import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setStakes, setUsePromocash } from 'sportsbook-betslip-duck';
import { get as lsGet, keys as lsKeys } from 'bv-local-storage';
import { useLoggedIn, useFeature } from 'bv-hooks';
import { isModal } from 'bv-services/route';
import {
  get as getBalance,
  subscribe,
  unsubscribe,
} from 'bv-services/account-balance';
import { resetBetslip } from 'sportsbook-betslip-thunks';
import BetslipContext from 'Betslip/context';
import MiniBetslip from 'Betslip/components/mini_betslip';
import FullBetslip from 'Betslip/components/full_betslip';
import StakeLimitsBreachModal from 'Betslip/components/errors/stake_limits_breach_modal';
import LossLimitsBreachModal from 'Betslip/components/errors/loss_limits_breach_modal';
import InsufficientFundsModal from 'Betslip/components/errors/insufficient_funds_modal';

const { bettingSettings } = window.BV;

const BetslipApp = ({ betslipType }) => {
  const [settings, setSettings] = useState({
    acceptablePriceChangePct: null, acceptLineChange: null,
  });
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [balance, setBalance] = useState();
  const loggedIn = useLoggedIn();
  const [tabsEnabled, tabsEnabledResolved] = useFeature('tabbed_betslip');

  const contextValue = useMemo(
    () => ({
      tabsEnabled,
      settings,
      setSettings,
      balance,
      betslipType,
    }),
    [tabsEnabled, settings, setSettings, balance, betslipType],
  );

  useEffect(() => {
    if (betslipType === 'full-betslip') {
      dispatch(resetBetslip());
    }
  }, []);

  // Re-add stakes to redux-state from localStorage
  useEffect(() => {
    const lsStakes = lsKeys().filter((key) => /^stakes-v2-/.test(key)).reduce((acc, key) => {
      acc[key.replace(/^stakes-v2-/, '')] = lsGet(key);
      return acc;
    }, {});
    dispatch(setStakes(lsStakes));
  }, []);

  useEffect(() => {
    dispatch(setUsePromocash(lsGet('usePromocash')));
  }, []);

  // Effect to get balance initially and subscribe to balance changes
  useEffect(() => {
    if (!loggedIn) return undefined;
    bettingSettings({ timeout: 5000 }).then(setSettings);
    getBalance().then(setBalance);
    subscribe(setBalance);

    return () => { unsubscribe(setBalance); };
  }, [loggedIn]);

  useEffect(() => {
    if (betslipType === 'mini-betslip') return;
    if (isModal(pathname)) window.RightSidebar.hide();
  }, [pathname]);

  if (!tabsEnabledResolved) return null;

  return (
    <BetslipContext.Provider value={contextValue}>
      { betslipType === 'mini-betslip'
        ? <MiniBetslip />
        : (
          <>
            <FullBetslip />
            <StakeLimitsBreachModal />
            <LossLimitsBreachModal />
            <InsufficientFundsModal />
          </>
        )}
    </BetslipContext.Provider>
  );
};

BetslipApp.propTypes = {
  betslipType: PropTypes.string.isRequired,
};

export default BetslipApp;
