import PropTypes from 'prop-types';

const Period = ({ periodDescription }) => (
  <div className="betslip-single__period">
    {periodDescription}
  </div>
);

Period.propTypes = {
  periodDescription: PropTypes.string.isRequired,
};

export default Period;
