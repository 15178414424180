import { compose, isEqual } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { betslipSetTab } from 'sportsbook-betslip-duck';
import {
  getBetslipSingles,
  getBetslipMultiples,
  getCurrentTab,
} from 'sportsbook-betslip-selector';

import TabsContainer from './tabs_container';

const TabsRedux = () => {
  const dispatch = useDispatch();
  const singles = useSelector(getBetslipSingles, isEqual);
  const multiples = useSelector(getBetslipMultiples, isEqual);
  const currentTab = useSelector(getCurrentTab, isEqual);
  const setTab = compose(dispatch, betslipSetTab);

  return (
    <TabsContainer
      singles={singles}
      multiples={multiples}
      currentTab={currentTab}
      setTab={setTab}
    />
  );
};

export default TabsRedux;
