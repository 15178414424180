import PropTypes from 'prop-types';
import BetreceiptView from './betreceipt_view';

const BetreceiptContainer = ({
  onClearAndClose, onReuse, submitResponse,
}) => {
  const { id, luckyDipBetReceipt } = submitResponse;
  const [firstSingle] = submitResponse.singles;
  const { totalStake, potentialReturns } = firstSingle;
  const [outcome] = Object.values(submitResponse.outcomes.byId);

  return (
    <BetreceiptView
      id={id}
      onClearAndClose={onClearAndClose}
      onReuse={onReuse}
      canReuse={!luckyDipBetReceipt}
      totalStake={totalStake}
      potentialReturns={potentialReturns}
      outcome={outcome}
      single={firstSingle}
      promocash={firstSingle.bonusFunds}
    />
  );
};

BetreceiptContainer.propTypes = {
  onClearAndClose: PropTypes.func.isRequired,
  onReuse: PropTypes.func.isRequired,
  submitResponse: PropTypes.isRequired,
};

export default BetreceiptContainer;
