import { useLayoutEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useLoggedIn } from 'bv-hooks';
import { throttle } from 'underscore';

let dragStartDelta;
const closeThreshold = 60;
const throttleSpeed = 10;

function getInnerHeight(el) {
  const computed = getComputedStyle(el);
  const padding = parseInt(computed.paddingTop, 10) + parseInt(computed.paddingBottom, 10);

  return el.clientHeight - padding;
}

const usePanelSwipe = ({
  hidePanel, panelNode, animateProp,
}) => {
  const loggedIn = useLoggedIn();
  const offset = loggedIn ? 24 : 55;
  const resetPanelPosition = () => {
    panelNode.classList.remove('dragging');
    const miniPanel = panelNode.querySelector('.betslip--mini');
    if (miniPanel) {
      miniPanel.removeAttribute('style');
    }
    // There are some timing issues where panel reset doesn't happen
    // in the right order, so we delay this for a bit
    window.setTimeout(() => {
      if (animateProp === 'transform') {
        panelNode.style.transform = '';
      }
      if (animateProp === 'top') {
        panelNode.style.top = `${offset}px`;
      }
      dragStartDelta = undefined;
    }, 10);
  };

  // Set height explicitly because of iOS safari
  // can't handle 100vh with toolbars as of now
  const setInitialHeight = () => {
    if (animateProp === 'top' && panelNode) {
      // only apply top-height for 1 column layouts (wide tablets have right sidebars)
      if (window.innerWidth < 1024) {
        panelNode.style.top = `${offset}px`;
        panelNode.style.height = `${window.innerHeight - offset}px`;
      } else {
        panelNode.removeAttribute('style');
      }
    }
  };

  useLayoutEffect(() => {
    setInitialHeight();
    window.addEventListener('resize', setInitialHeight);
    return () => {
      window.removeEventListener('resize', setInitialHeight);
    };
  }, [panelNode]);

  return useSwipeable({
    preventScrollOnSwipe: true,
    delta: 1, // start triggering onSwiping events after the first pixel
    // if at the end of a swipe you moved more than 30 pixels down, trigger hiding of the panel
    onSwipedDown: (eventData) => {
      if (Math.abs(eventData.deltaY) > closeThreshold) {
        resetPanelPosition();
        hidePanel();
      }
    },
    onSwipeStart: () => {
      panelNode.classList.add('dragging');
      // IOS safari can't animate sizes correctly with transform and fixed
      // setting margins and removing bottom seem to make dragging work
      // without visual jumping around
      const miniPanel = panelNode.querySelector('.betslip--mini');
      if (miniPanel) {
        miniPanel.style.bottom = 'initial';
        miniPanel.style.marginBottom = `${getInnerHeight(miniPanel) - 54}px`;
      }
    },
    // when a swiping is over reset css + bodyscroll
    onSwiped: resetPanelPosition,
    // throttle swiping events to once every 16 milisec to maitain 60 fps smooth movement
    onSwiping: throttle((eventData) => {
      // record delta the first time we start moving the panel, if the gesture started while
      // doesn't "jump" once it's moving
      if (dragStartDelta === undefined) {
        dragStartDelta = eventData.deltaY;
      }
      const delta = eventData.deltaY - dragStartDelta;

      if (eventData.dir === 'Down') {
        // manipulate style directly for speed
        if (animateProp === 'transform') {
          panelNode.style.transform = `translate(0, ${delta}px)`;
        }
        if (animateProp === 'top') {
          panelNode.style.top = `${offset + delta}px`;
        }
      }
    }, throttleSpeed),
  });
};

export default usePanelSwipe;
/* eslint no-param-reassign: "off" */
