import PropTypes from 'prop-types';
import { showMeetingSportIds } from 'Betslip/helpers';
import Market from './market';
import Period from './period';
import Event from './event';
import Terms from './terms';

const SingleDescription = ({
  stakeId,
  marketTypeDescription,
  periodDescription,
  eventDescription,
  meetingDescription,
  sportId,
  placeTermsDescription,
  hideEW,
  ewAllowed,
}) => (
  <div className="betslip-single__description">
    <Market marketTypeDescription={marketTypeDescription} />
    <Event
      showMeetingDescription={showMeetingSportIds.includes(sportId)}
      meetingDescription={meetingDescription}
      eventDescription={eventDescription}
    />
    <Period periodDescription={periodDescription} />
    <Terms
      ewAllowed={ewAllowed}
      hideEW={hideEW}
      stakeId={stakeId}
      placeTermsDescription={placeTermsDescription}
    />
  </div>
);

SingleDescription.propTypes = {
  stakeId: PropTypes.isRequired,
  marketTypeDescription: PropTypes.isRequired,
  periodDescription: PropTypes.isRequired,
  eventDescription: PropTypes.isRequired,
  placeTermsDescription: PropTypes.isRequired,
  sportId: PropTypes.isRequired,
  meetingDescription: PropTypes.isRequired,
  hideEW: PropTypes.bool,
  ewAllowed: PropTypes.isRequired,
};

SingleDescription.defaultProps = {
  hideEW: false,
};

export default SingleDescription;
