import { compose } from 'underscore';
import { useDispatch } from 'react-redux';
import { setMultiplesExpanded } from 'sportsbook-betslip-duck';
import { t } from 'bv-i18n';

const ShowMoreView = () => {
  const dispatch = useDispatch();
  return (
    <button
      className="bvs-button is-default multiples-more"
      type="button"
      onClick={compose(dispatch, setMultiplesExpanded)}
    >
      {t('show_more')}
    </button>
  );
};

export default ShowMoreView;
