import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';
import { potentialReturns } from 'Betslip/helpers';

const BetreceiptSummary = ({
  totalStake,
  grossTotalPotentialReturns,
  promocash,
}) => (
  <div className="betslip__details__summary">
    <div className="summary_row">
      <span>{t('betslip.receipt.total_stake')}</span>
      <span className="summary_value">
        {promocash && <span className="bvs-icon is-promo-cash" />}
        {toCurrency(totalStake, { decimalPlaces: 2 })}
      </span>
    </div>
    <div className="summary_row">
      <span>{t('betslip.receipt.potential_returns')}</span>
      <span className="summary_value">{potentialReturns(grossTotalPotentialReturns)}</span>
    </div>
  </div>
);

BetreceiptSummary.propTypes = {
  totalStake: PropTypes.isRequired,
  grossTotalPotentialReturns: PropTypes.isRequired,
  promocash: PropTypes.isRequired,
};

export default BetreceiptSummary;
