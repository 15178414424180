import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const BetTypeInfo = ({ description, winBetTypeId, onClose }) => (
  <RenderInBody>
    <Modal
      title={description}
      info
      className="betslip-bet-type-info"
      actions={[{
        label: t('close'),
        onClick: onClose,
        className: 'bvs-button-alternative is-secondary',
      }]}

    >
      <p>
        {t(`betslip_bet_type_tooltips.${winBetTypeId}`)}
      </p>
    </Modal>
  </RenderInBody>
);

BetTypeInfo.propTypes = {
  description: PropTypes.isRequired,
  winBetTypeId: PropTypes.isRequired,
  onClose: PropTypes.isRequired,
};

export default BetTypeInfo;
