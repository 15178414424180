import { useContext } from 'react';
import { isEqual } from 'underscore';
import BetslipContext from 'Betslip/context';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLoggedIn } from 'bv-hooks';
import { getEnhancedOutcomesById } from 'EnhanedOutcomeOffers/selectors';
import { submitBetslip } from 'sportsbook-betslip-thunks';
import {
  getStakes,
  getBetslipSingles,
  makeGetBetslipOutcomes,
  getUsePromocash,
  getRemoveStakeForBonusBet,
  getAcceptAllPriceChangesToggleLoading,
  getStatus,
  getSubmitResponse,
  getTaxDeductPrc,
  getCurrentTab,
} from 'sportsbook-betslip-selector';
import filteredMultiples from 'Betslip/services/filtered_multiples';
import { singlesFilter, multiplesFilter } from 'Betslip/helpers';
import SubmitButtonContainer from './submit_button_container';

const getBetslipOutcomes = makeGetBetslipOutcomes();

const SubmitButtonRedux = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stakes = useSelector(getStakes, isEqual);
  const singles = useSelector(getBetslipSingles, isEqual);
  const { multiples, multiplesExpanded } = filteredMultiples({
    removeSuspended: true,
  });
  const outcomes = useSelector(getBetslipOutcomes, isEqual);
  const currentTab = useSelector(getCurrentTab);
  const enhancedOutcomesById = useSelector(getEnhancedOutcomesById, isEqual);
  const usePromocash = useSelector(getUsePromocash, isEqual);
  const isAcceptPriceChangesLoading = useSelector(
    getAcceptAllPriceChangesToggleLoading,
    isEqual,
  );
  const removeStakeForBonusBet = useSelector(
    getRemoveStakeForBonusBet,
    isEqual,
  );
  const status = useSelector(getStatus, isEqual);
  const submitResponse = useSelector(getSubmitResponse, isEqual);
  const { betslipType, tabsEnabled } = useContext(BetslipContext);
  const taxDeductPrc = useSelector(getTaxDeductPrc, isEqual);
  const loggedIn = useLoggedIn();

  const filters = {
    singlesFilter: singlesFilter(tabsEnabled, currentTab),
    multiplesFilter: multiplesFilter(
      tabsEnabled,
      currentTab,
      multiplesExpanded,
    ),
  };

  const onSubmit = () => {
    dispatch(
      submitBetslip(betslipType, enhancedOutcomesById, filters),
    );
  };

  const onDeposit = () => history.push(`/quick_deposit/${submitResponse.deposit || ''}`);
  const onLogin = () => history.push('/login');

  return (
    <SubmitButtonContainer
      status={status}
      singles={singles}
      enhancedOutcomesById={enhancedOutcomesById}
      outcomes={outcomes}
      stakes={stakes}
      usePromocash={usePromocash}
      removeStakeForBonusBet={removeStakeForBonusBet}
      isAcceptPriceChangesLoading={isAcceptPriceChangesLoading}
      taxDeductPrc={taxDeductPrc}
      submitResponse={submitResponse}
      loggedIn={loggedIn}
      onSubmit={onSubmit}
      onDeposit={onDeposit}
      onLogin={onLogin}
      multiples={multiples}
    />
  );
};

export default SubmitButtonRedux;
