import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import ReturnsRow from './returns_row';

const ReturnRows = ({ potentialReturns }) => (
  potentialReturns.netEnhanced <= 0
    ? (
      <>
        <ReturnsRow
          label={t('betslip.receipt.potential_returns')}
          returns={potentialReturns.netTotal}
        />
        {potentialReturns.placeNetTotal ? (
          <ReturnsRow
            label={t('betslip.receipt.if_placed_returns')}
            returns={potentialReturns.placeNetTotal}
          />
        ) : null}
      </>
    )
    : (
      <>
        <ReturnsRow
          label={t('betslip.receipt.normal_returns')}
          returns={potentialReturns.netNormal}
        />
        <ReturnsRow
          label={t('betslip.receipt.enhanced_returns')}
          returns={potentialReturns.netEnhanced}
        />
        <ReturnsRow
          label={t('betslip.receipt.potential_returns')}
          returns={potentialReturns.netTotal}
        />
      </>
    )
);

ReturnRows.propTypes = {
  potentialReturns: PropTypes.isRequired,
};

export default ReturnRows;
