import { useContext } from 'react';
import PropTypes from 'prop-types';
import { BETSLIP_STATUS } from 'sportsbook-betslip-duck';
import { t } from 'bv-i18n';
import BetslipContext from 'Betslip/context';
import {
  getStakeId,
  hasAnyLineChanges,
  hasAnyLineFollowed,
  hasAnyPriceChanges,
  hasAtLeastOneStake,
  isAllHidden,
  isAllHiddenOrSuspended,
  isAllSuspended,
} from 'Betslip/helpers';
import submitButtonAttributes from 'Betslip/services/submit_button_attributes';
import {
  netTotalPotentialReturns,
  netTotalPlacedReturns,
  netTotalEnhancedReturns,
} from 'Betslip/services/potential_returns';
import getTotalStake from 'Betslip/services/total_stake';
import SubmitButtonView from './submit_button_view';

const SubmitButtonContainer = ({
  status,
  singles,
  multiples,
  outcomes,
  stakes,
  enhancedOutcomesById,
  usePromocash,
  removeStakeForBonusBet,
  taxDeductPrc,
  isAcceptPriceChangesLoading,
  onSubmit,
  onDeposit,
  onLogin,
  loggedIn,
}) => {
  const { balance, betslipType } = useContext(BetslipContext);

  // Don't show submit when betslip is empty
  if (singles.length === 0 && multiples.length === 0) {
    return null;
  }

  const {
    buttonProps, buttonText, showSpinner, subtitleKey,
  } = submitButtonAttributes({
    status,
    balance,
    usePromocash,
    isAcceptPriceChangesLoading,
    onSubmit,
    onDeposit,
    onLogin,
    loggedIn,
    getTotalStake: () => getTotalStake(singles, multiples, stakes),
    hasAnyLineChanges: () => hasAnyLineChanges(singles),
    hasAnyLineFollowed: () => hasAnyLineFollowed(singles),
    hasAnyPriceChanges: () => hasAnyPriceChanges(singles),
    hasAtLeastOneStake: () => hasAtLeastOneStake(outcomes, singles, multiples, stakes),
    isAllHidden: () => isAllHidden(outcomes),
    isAllHiddenOrSuspended: () => isAllHiddenOrSuspended(outcomes),
    isAllSuspended: () => isAllSuspended(outcomes),
    submitText: t('betslip.button.place_bets'),
  });

  const returnsOpts = {
    singles, multiples, stakes, enhancedOutcomesById, usePromocash, removeStakeForBonusBet,
  };
  const hasEW = !!singles.map(getStakeId).find((stakeId) => stakes[stakeId] && stakes[stakeId].ews);

  const potentialReturns = netTotalPotentialReturns(returnsOpts, taxDeductPrc);
  const placedReturns = netTotalPlacedReturns(returnsOpts, taxDeductPrc);
  const enhancedReturns = netTotalEnhancedReturns(returnsOpts, taxDeductPrc);

  return (
    <SubmitButtonView
      buttonProps={buttonProps}
      showSpinner={showSpinner}
      subtitleKey={subtitleKey}
      buttonText={buttonText}
      betslipType={betslipType}
      potentialReturns={
        status === BETSLIP_STATUS.READY ? potentialReturns : 0
      }
      placedReturns={
        status === BETSLIP_STATUS.READY && hasEW ? placedReturns : 0
      }
      enhancedReturns={
        status === BETSLIP_STATUS.READY ? enhancedReturns : 0
      }
    />
  );
};

SubmitButtonContainer.propTypes = {
  status: PropTypes.isRequired,
  stakes: PropTypes.isRequired,
  singles: PropTypes.isRequired,
  enhancedOutcomesById: PropTypes.isRequired,
  outcomes: PropTypes.isRequired,
  multiples: PropTypes.isRequired,
  usePromocash: PropTypes.isRequired,
  removeStakeForBonusBet: PropTypes.isRequired,
  isAcceptPriceChangesLoading: PropTypes.isRequired,
  taxDeductPrc: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDeposit: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

export default SubmitButtonContainer;
