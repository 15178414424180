import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { getSubmitResponse, getPreviousOutcomeIds } from 'sportsbook-betslip-selector';
import { clearBetslip, addMultipleToBetslip } from 'sportsbook-betslip-thunks';
import { clearSubmitResponse, emptyBetslip } from 'sportsbook-betslip-duck';

import BetreceiptContainer from './betreceipt_container';

const BetreceiptRedux = () => {
  const dispatch = useDispatch();
  const submitResponse = useSelector(getSubmitResponse, isEqual);
  const onClearAndClose = () => {
    dispatch(submitResponse.luckyDipBetReceipt ? clearSubmitResponse() : clearBetslip());
  };
  const previousOutcomeIds = useSelector(getPreviousOutcomeIds, isEqual);
  const onReuse = () => {
    dispatch(emptyBetslip({ clearSubmitResponse: true }));
    dispatch(addMultipleToBetslip(previousOutcomeIds));
  };

  return (
    <BetreceiptContainer
      onClearAndClose={onClearAndClose}
      onReuse={onReuse}
      submitResponse={submitResponse}
    />
  );
};

export default BetreceiptRedux;
