import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { withErrorBoundary } from 'bv-error-boundary';
import { isAnySuspended, hasAnyPriceChanges } from 'Betslip/helpers';
import BetslipContext from 'Betslip/context';
import { isSomeSingleNonCalculable } from 'Betslip/services/potential_returns';
import filteredMultiples from 'Betslip/services/filtered_multiples';
import {
  getBetslipSingles,
  makeGetBetslipOutcomes,
  getStakes,
} from 'sportsbook-betslip-selector';

import MultiplesView from './multiples_view';

const getBetslipOutcomes = makeGetBetslipOutcomes();

const MultiplesRedux = ({ keyboardRef }) => {
  const { tabsEnabled } = useContext(BetslipContext);
  const outcomes = useSelector(getBetslipOutcomes, isEqual);
  const suspended = isAnySuspended(outcomes);
  const singles = useSelector(getBetslipSingles, isEqual);
  const stakes = useSelector(getStakes, isEqual);
  const anySpSingle = isSomeSingleNonCalculable(singles, stakes);
  const { multiples, unfilteredMultiplesCount, multiplesExpanded } = filteredMultiples();
  const priceChanged = hasAnyPriceChanges(singles);

  if (multiples.length === 0) return null;

  return (
    <MultiplesView
      multiplesCount={unfilteredMultiplesCount}
      multiplesExpanded={multiplesExpanded}
      keyboardRef={keyboardRef}
      multiples={multiples}
      anySpSingle={anySpSingle}
      tabsEnabled={tabsEnabled}
      suspended={suspended}
      priceChanged={priceChanged}
    />
  );
};

MultiplesRedux.propTypes = {
  keyboardRef: PropTypes.isRequired,
};

export default withErrorBoundary(MultiplesRedux, { FallbackComponent: () => <div /> });
