import PropTypes from 'prop-types';
import classnames from 'classnames';
import { usePriceFormatId } from 'bv-hooks';
import { format as priceFormat } from 'bv-helpers/price';
import { t } from 'bv-i18n';

const SinglePriceView = ({
  single, enhancedPrice, priceChanged, selectionLocked, spSelected, toggleSP,
}) => {
  const priceFormatId = usePriceFormatId();
  const priceText = (single.decimalPrice !== 0 && !spSelected) ? priceFormat(single.textFormattedPrice, priceFormatId) : t('sp');
  return (
    <div
      className={classnames('single-price option-column', { priceChanged, selectionLocked })}
    >
      <div>
        {enhancedPrice && (
          <span className="odds-price outcome-enhanced-odds">
            <span className="bvs-event-icon is-bet-boost" />
            {priceFormat(enhancedPrice, priceFormatId)}
          </span>
        )}
        <span
          className={
            classnames({
              'outcome-previous-odds': enhancedPrice,
              'odds-price': !enhancedPrice,
            })
          }
        >
          {
            single.spAllowed
              ? <div type="button" onClick={toggleSP}>{priceText}</div>
              : priceText
          }
        </span>
      </div>
    </div>
  );
};

SinglePriceView.propTypes = {
  single: PropTypes.isRequired,
  enhancedPrice: PropTypes.isRequired,
  priceChanged: PropTypes.isRequired,
  selectionLocked: PropTypes.isRequired,
  spSelected: PropTypes.bool.isRequired,
  toggleSP: PropTypes.func.isRequired,
};

export default SinglePriceView;

/* eslint jsx-a11y/label-has-associated-control: 'off'  */
