import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { showMeetingSportIds, splitOutcomeDescriptionWithLine } from 'Betslip/helpers';
import Market from './market';
import Period from './period';
import Event from './event';
import Terms from './terms';
import InRunning from './in_running';

const SingleDescription = ({
  stakeId,
  outcomeDescription,
  marketTypeDescription,
  periodDescription,
  eventDescription,
  meetingDescription,
  sportId,
  placeTermsDescription,
  ewAllowed,
  hideEW,
  inRunning,
  outcomeLineDistance,
}) => {
  let lineValue = false;
  // Some old Horse-racing events have placeholders in the outcome description
  // that we need to localize
  let description = outcomeDescription.replace(/<favourite>/gi, t('favourite'));
  // try splitting description to outcome desc and line value
  if (Number.isInteger(outcomeLineDistance)) {
    [description, lineValue] = splitOutcomeDescriptionWithLine(description);
  }

  return (
    <div className="betslip-single__description">
      <div className="betslip-single__outcome">
        {description}
        {lineValue && (
          <>
            &nbsp;
            <span className="betslip-single__description-line">
              {lineValue}
            </span>
          </>
        )}
      </div>
      <Market marketTypeDescription={marketTypeDescription} />
      <Period periodDescription={periodDescription} />
      <Event
        showMeetingDescription={showMeetingSportIds.includes(sportId)}
        meetingDescription={meetingDescription}
        eventDescription={eventDescription}
      />
      <InRunning inRunning={inRunning} />
      <Terms
        ewAllowed={ewAllowed}
        hideEW={hideEW}
        stakeId={stakeId}
        placeTermsDescription={placeTermsDescription}
      />
    </div>
  );
};

SingleDescription.propTypes = {
  stakeId: PropTypes.isRequired,
  outcomeDescription: PropTypes.isRequired,
  marketTypeDescription: PropTypes.isRequired,
  periodDescription: PropTypes.isRequired,
  eventDescription: PropTypes.isRequired,
  placeTermsDescription: PropTypes.isRequired,
  sportId: PropTypes.isRequired,
  meetingDescription: PropTypes.isRequired,
  hideEW: PropTypes.bool,
  inRunning: PropTypes.bool.isRequired,
  outcomeLineDistance: PropTypes.isRequired,
  ewAllowed: PropTypes.isRequired,
};

SingleDescription.defaultProps = {
  hideEW: false,
};

export default SingleDescription;
