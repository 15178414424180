import { useEffect } from 'react';
import { isEqual } from 'underscore';
import { useLoggedIn } from 'bv-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getFirstBetslipSingle, getStake, makeGetBetslipOutcomes } from 'sportsbook-betslip-selector';
import { setStakeValue } from 'sportsbook-betslip-duck';
import QuickStakesContainer from 'BetslipKeypad/components/quick_stakes_container';
import getQuickStakes from 'BetslipKeypad/api/quick_stakes';
import { getStakeId, isSuspended } from 'Betslip/helpers';
import { isDesktop } from 'bv';

const getBetslipOutcome = makeGetBetslipOutcomes();

const QuickStakes = () => {
  const loggedIn = useLoggedIn();
  const dispatch = useDispatch();
  const single = useSelector(getFirstBetslipSingle, isEqual);
  const outcomes = useSelector((state) => getBetslipOutcome(state, single), isEqual);
  const stakeId = getStakeId(single);
  const stakeValue = useSelector((state) => getStake(state, stakeId), isEqual);

  useEffect(() => {
    getQuickStakes();
  }, [loggedIn, isDesktop()]);

  if (!single || outcomes.length === 0) {
    return null;
  }
  if (isDesktop()) {
    return null;
  }
  if (isSuspended(outcomes[0])) {
    return null;
  }

  const onChange = (value) => {
    dispatch(setStakeValue(stakeId, value));
  };

  return (
    <QuickStakesContainer
      onChange={onChange}
      sportId={single.sportId}
      value={stakeValue.value}
    />
  );
};

export default QuickStakes;
