import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useEventBus } from 'bv-hooks';
import { locale } from 'bv-i18n';

const CrackTheCode = ({ betslipReference, isEmptyBetslip }) => {
  const [descriptions, setDescriptions] = useState();

  useEventBus(
    `/c/ctc/betslip/${betslipReference}/${locale()}`,
    (err, response) => {
      if (err || !response?.body) return;

      setDescriptions(response.body?.eligibility === 'ELIGIBLE'
        ? response.body?.descriptions
        : null);
    },
    [betslipReference],
  );

  useEffect(() => {
    if (isEmptyBetslip) {
      setDescriptions(null);
    }
  }, [isEmptyBetslip]);

  if (!descriptions || isEmptyBetslip) return null;

  return (
    <div className="betslip__ctc">
      <span className="betslip__ctc-logo" />
      <span className="betslip__ctc-title">
        {descriptions.CTC_BETSLIP_TITLE || ''}
      </span>
      <span className="betslip__ctc-subtitle">
        {descriptions.CTC_BETSLIP_SUBTITLE || ''}
      </span>
    </div>
  );
};

CrackTheCode.propTypes = {
  betslipReference: PropTypes.string.isRequired,
  isEmptyBetslip: PropTypes.bool,
};

CrackTheCode.defaultProps = {
  isEmptyBetslip: false,
};

export default CrackTheCode;
