import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';

const EwToggleView = ({ stakeId, toggleEWS, active }) => (
  <div className="option-column ews-toggle">
    <label htmlFor={`ews-toggle-${stakeId}`}>
      <input style={{ display: 'none' }} checked={active} id={`ews-toggle-${stakeId}`} type="checkbox" />
      <button
        type="button"
        onClick={toggleEWS}
        className={classnames('bvs-button-chip', { active })}
      >
        {t('betslip.ew_toggle')}
      </button>
    </label>
  </div>
);

EwToggleView.propTypes = {
  stakeId: PropTypes.func.isRequired,
  toggleEWS: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default EwToggleView;
