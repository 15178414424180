import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { getSubmitResponse, getPreviousOutcomeIds } from 'sportsbook-betslip-selector';
import { clearSubmitResponse, emptyBetslip } from 'sportsbook-betslip-duck';
import { clearBetslip, addMultipleToBetslip } from 'sportsbook-betslip-thunks';

import BetreceiptContainer from './betreceipt_container';

const { RightSidebar } = window;

const BetreceiptRedux = () => {
  const dispatch = useDispatch();
  const submitResponse = useSelector(getSubmitResponse, isEqual);
  const previousOutcomeIds = useSelector(getPreviousOutcomeIds, isEqual);
  const onClearAndClose = () => {
    RightSidebar.hide();
    clearAllBodyScrollLocks();
    dispatch(submitResponse.luckyDipBetReceipt ? clearSubmitResponse() : clearBetslip());
  };
  const onReuse = () => {
    dispatch(emptyBetslip({ clearSubmitResponse: true }));
    dispatch(addMultipleToBetslip(previousOutcomeIds));
    // re-initialise scroll-locking hacks for IOS
    // after betslip with selections re-shown and
    // receipt is removed
    window.setTimeout(() => {
      RightSidebar.showBetslip();
    }, 2);
  };

  return (
    <BetreceiptContainer
      onClearAndClose={onClearAndClose}
      onReuse={onReuse}
      submitResponse={submitResponse}
    />
  );
};

export default BetreceiptRedux;
