import { Provider } from 'react-redux';
import { addMiddleware } from 'redux-dynamic-middlewares';
import ToggleRedux from './components/toggle_redux';
import AcceptAllPriceChangesToggleMiddleware from './middleware';
import reducer from './duck';

const { store, addReducers } = window.reduxState;

addReducers({ acceptAllPriceChangesToggle: reducer });
addMiddleware(AcceptAllPriceChangesToggleMiddleware);

const AcceptAllPriceChangesToggle = (props) => (
  <Provider store={store}>
    <ToggleRedux {...props} />
  </Provider>
);

export default AcceptAllPriceChangesToggle;
