import PropTypes from 'prop-types';
import usePanelSwipe from 'Betslip/services/use_panel_swipe';

const PanelHandle = ({ hidePanel, panelNode, animateProp }) => {
  const swipeHandlers = usePanelSwipe({ hidePanel, panelNode, animateProp });

  return (
    <div className="betslip__handle" {...swipeHandlers} onClick={hidePanel}>
      <div />
    </div>
  );
};

PanelHandle.propTypes = {
  hidePanel: PropTypes.func.isRequired,
  panelNode: PropTypes.instanceOf(Object),
  animateProp: PropTypes.string,
};

PanelHandle.defaultProps = {
  panelNode: null,
  animateProp: null,
};

export default PanelHandle;
