import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'underscore';
import { v as bvVar } from 'bv';
import { useFeature } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { BETSLIP_STATUS } from 'sportsbook-betslip-duck';
import { showBetslipAcceptAllLineChanges, showBetslipAcceptAllPriceChanges } from 'global-config';
import { isAllHiddenOrSuspended } from 'Betslip/helpers';
import usePanelSwipe from 'Betslip/services/use_panel_swipe';
import Singles from 'Betslip/components/singles';
import QuickStakes from 'Betslip/components/mini_betslip/quick_stakes';
import SubmitButton from 'Betslip/components/submit_button';
import PromocashToggle from 'Betslip/components/promocash_toggle';
import AcceptAllPriceChangesToggle from 'AcceptAllPriceChangesToggle';
import AcceptAllLineChangesToggle from 'AcceptAllLineChangesToggle';
import Betreceipt from 'Betslip/components/mini_betslip/betreceipt';
import TaxInfo from 'Betslip/components/tax_info_dialog';
import PanelHandle from 'Betslip/components/panel_handle';
import GeneralError from 'Betslip/components/errors/general_error';
import PayoutDisclaimer from 'Betslip/components/payout_disclaimer';
import PanicButton from 'PanicButton';
import CrackTheCode from 'Betslip/components/crack_the_code';

const PanelView = ({
  betslipReference,
  betslipStatus,
  hidePanel,
  acceptablePriceChangePct,
  acceptAllLineChanges,
  submitResponse,
  outcomes,
  isEmptyBetslip,
  hasAnyLineDistanceSingle,
}) => {
  const [mounted, setMounted] = useState(false);
  const [followLineChangesFF, followLineChangesFFLoaded] = useFeature('follow_line_changes');
  const keyboardRef = useRef();
  const panelRef = useRef();
  const floatingNavNode = panelRef.current
    ? panelRef.current.parentNode
    : null;

  const topClass = classnames(
    'betslip betslip--mini',
    {
      'betslip--disabled': ![BETSLIP_STATUS.READY, BETSLIP_STATUS.FETCH].includes(betslipStatus),
      'bets--suspended': isAllHiddenOrSuspended(outcomes),
    },
  );

  const swipeHandlers = usePanelSwipe({
    hidePanel,
    panelNode: floatingNavNode,
    animateProp: 'transform',
  });

  useEffect(() => setMounted(true), []);

  return (
    <div id="mini-betslip" className={topClass} ref={panelRef}>
      <PanelHandle hidePanel={hidePanel} panelNode={floatingNavNode} animateProp="transform" />
      { bvVar('crackTheCode').standard && (
        <CrackTheCode betslipReference={betslipReference} isEmptyBetslip={isEmptyBetslip} />
      )}
      <PanicButton source="mini-betslip-top" />
      { betslipStatus === BETSLIP_STATUS.FETCH && <Spinner /> }
      { betslipStatus !== BETSLIP_STATUS.FETCH
        && (isEmpty(submitResponse) || submitResponse.success === false) && (
        <div {...(mounted ? swipeHandlers : {})}>
          <GeneralError />
          <div className="betslip__bet-list">
            <Singles keyboardRef={keyboardRef} />
          </div>
          <PayoutDisclaimer hidePanel={hidePanel} />
          <div className="betslip__details">
            <div className="betslip__keypad-container">
              <QuickStakes />
            </div>
            <div className="betslip__details__toggles">
              <PromocashToggle />
              { showBetslipAcceptAllPriceChanges
                && (
                <AcceptAllPriceChangesToggle
                  acceptablePriceChangePct={acceptablePriceChangePct}
                />
                )}
              { showBetslipAcceptAllLineChanges
                  && hasAnyLineDistanceSingle
                  && followLineChangesFF
                  && followLineChangesFFLoaded
                  && <AcceptAllLineChangesToggle acceptAllLineChanges={acceptAllLineChanges} />}
            </div>
          </div>
          <div className="betslip__stake-button-wrapper">
            <SubmitButton />
          </div>
          <TaxInfo />
          <div className="betslip__details">
            <div className="betslip__keypad-container" ref={keyboardRef} />
          </div>
        </div>
      )}
      { betslipStatus === BETSLIP_STATUS.READY && submitResponse && submitResponse.success && (
        <div {...(mounted ? swipeHandlers : {})}>
          <Betreceipt />
        </div>
      )}
    </div>
  );
};

PanelView.propTypes = {
  betslipReference: PropTypes.string.isRequired,
  betslipStatus: PropTypes.string.isRequired,
  isEmptyBetslip: PropTypes.bool.isRequired,
  acceptablePriceChangePct: PropTypes.number.isRequired,
  acceptAllLineChanges: PropTypes.bool.isRequired,
  hidePanel: PropTypes.func.isRequired,
  submitResponse: PropTypes.isRequired,
  outcomes: PropTypes.isRequired,
  hasAnyLineDistanceSingle: PropTypes.bool.isRequired,
};

export default PanelView;
