import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const InRunning = ({ inRunning }) => {
  if (!inRunning) {
    return null;
  }

  return (
    <div className="betslip-single__in-running">
      {t('in_play_bets_indicator.in_play')}
    </div>
  );
};

InRunning.propTypes = {
  inRunning: PropTypes.bool.isRequired,
};

export default InRunning;
