import PropTypes from 'prop-types';
import { SmallSpinner } from 'bv-components';
import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';

const SubmitButtonView = ({
  buttonProps,
  buttonText,
  showSpinner,
  subtitleKey,
  potentialReturns,
  placedReturns,
  enhancedReturns,
  betslipType,
}) => (
  <button type="button" {...buttonProps} data-testid="betslip-submit">
    <span className="betslip-submit__button">
      {buttonText}
      {subtitleKey && (
        <span className="betslip-submit__button-subtitle">
          {t(`betslip.button.${subtitleKey}`)}
        </span>
      )}
    </span>
    {betslipType === 'mini-betslip' && potentialReturns > 0 && !buttonProps.disabled && (
    <span>
      {t('betslip.button.potential_returns', {
        returns: toCurrency(potentialReturns, { decimalPlaces: 2 }),
      })}
      {placedReturns > 0 && (
        <>
          {' '}
          (
          {t('betslip.potential_returns.if_placed', {
            returns: toCurrency(placedReturns, { decimalPlaces: 2 }),
          })}
          )
        </>
      )}
      {enhancedReturns > 0 && (
        <>
          {' '}
          (
          {t('betslip.potential_returns.enhanced', {
            returns: toCurrency(enhancedReturns, { decimalPlaces: 2 }),
          })}
          )
        </>
      )}
    </span>
    )}
    {showSpinner && <SmallSpinner />}
  </button>
);

SubmitButtonView.propTypes = {
  buttonProps: PropTypes.isRequired,
  buttonText: PropTypes.isRequired,
  showSpinner: PropTypes.isRequired,
  subtitleKey: PropTypes.isRequired,
  potentialReturns: PropTypes.isRequired,
  placedReturns: PropTypes.isRequired,
  enhancedReturns: PropTypes.isRequired,
  betslipType: PropTypes.isRequired,
};

export default SubmitButtonView;
