import PropTypes from 'prop-types';
import { receiptGrossTotalReturns } from 'Betslip/helpers';
import BetreceiptView from './betreceipt_view';

const BetreceiptContainer = ({
  onClearAndClose, onReuse, submitResponse,
}) => {
  const outcomesById = submitResponse.outcomes.byId;
  const {
    id, singles, multiples, luckyDipBetReceipt,
  } = submitResponse;
  const bets = [...singles, ...multiples];
  const promocash = bets.some((b) => b.bonusFunds);
  const totalStake = bets.reduce(((acc, bet) => acc + bet.totalStake), 0);

  return (
    <BetreceiptView
      id={id}
      outcomesById={outcomesById}
      singles={singles}
      multiples={multiples}
      onClearAndClose={onClearAndClose}
      onReuse={onReuse}
      canReuse={!luckyDipBetReceipt}
      totalStake={totalStake}
      grossTotalPotentialReturns={receiptGrossTotalReturns(bets)}
      promocash={promocash}
    />
  );
};

BetreceiptContainer.propTypes = {
  onClearAndClose: PropTypes.func.isRequired,
  onReuse: PropTypes.func.isRequired,
  submitResponse: PropTypes.isRequired,
};

export default BetreceiptContainer;
