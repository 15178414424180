import { useEffect, useState } from 'react';
import { isEqual } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { getSubmitResponse } from 'sportsbook-betslip-selector';
import { betslipSubmitClear } from 'sportsbook-betslip-duck';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';
import { linkLocalizer } from 'bv-helpers/locale';

const InsufficientFundsModal = () => {
  const dispatch = useDispatch();
  const [closed, setClosed] = useState(false);
  const submitResponse = useSelector(getSubmitResponse, isEqual);
  const { RightSidebar } = window;

  // reset show-error-state on every submit
  useEffect(() => {
    setClosed(false);
  }, [submitResponse]);

  if (submitResponse.type !== 'insufficient_funds') return null;
  if (closed) return null;

  return (
    <RenderInBody>
      <Modal
        warning
        title={t('betslip.insufficient_funds_modal.title')}
        actions={[
          {
            label: t('betslip.insufficient_funds_modal.deposit_button'),
            href: linkLocalizer(`/quick_deposit/${submitResponse.deposit}`),
            warning: true,
            className: 'bvs-button-alternative is-secondary',
            onClick: () => {
              dispatch(betslipSubmitClear());
              RightSidebar.hide();
              setClosed(true);
            },
          },
          {
            label: t('betslip.insufficient_funds_modal.close_button'),
            danger: true,
            className: 'bvs-button-alternative is-secondary',
            onClick: () => {
              dispatch(betslipSubmitClear());
              setClosed(true);
            },
          },
        ]}
      >
        <p>{t('betslip.insufficient_funds_modal.there_is_insufficient_funds')}</p>
      </Modal>
    </RenderInBody>
  );
};

export default InsufficientFundsModal;
