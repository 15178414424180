import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { setUsePromocash } from 'sportsbook-betslip-duck';
import { set as lsSet } from 'bv-local-storage';
import BetslipContext from 'Betslip/context';
import {
  isAllBonusEligible,
  getUsePromocash,
  hasAnyBonusEligibleBet,
  hasAnyStakes,
  makeGetBetslipOutcomes,
} from 'sportsbook-betslip-selector';

import PromocashToggleView from './promocash_toggle_view';

const getBetslipOutcomes = makeGetBetslipOutcomes();

const PromocashToggleRedux = () => {
  const dispatch = useDispatch();
  const { betslipType } = useContext(BetslipContext);
  const usePromocash = useSelector(getUsePromocash, isEqual);
  const onToggle = () => {
    const newValue = !usePromocash;
    lsSet('usePromocash', newValue);
    dispatch(setUsePromocash(newValue));
  };
  const hasNoStakes = !useSelector(hasAnyStakes, isEqual);
  const hasEligible = useSelector(hasAnyBonusEligibleBet, isEqual);
  const allEligible = useSelector(isAllBonusEligible, isEqual);
  const showToggle = (hasNoStakes && hasEligible && betslipType === 'full-betslip') || hasEligible;
  const outcomes = useSelector(getBetslipOutcomes, isEqual);
  const disabled = showToggle && !allEligible;

  useEffect(() => {
    if (disabled) {
      lsSet('usePromocash', false);
      dispatch(setUsePromocash(false));
    }
  }, [disabled]);

  if (!showToggle) return null;

  return (
    <PromocashToggleView
      usePromocash={usePromocash}
      onToggle={onToggle}
      disabled={disabled}
      outcomes={outcomes}
    />
  );
};

export default PromocashToggleRedux;
