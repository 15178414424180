import { isEqual } from 'underscore';
import { isDesktop } from 'bv';
import { useDispatch, useSelector } from 'react-redux';
import { withErrorBoundary } from 'bv-error-boundary';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isAnyWithLineDistance } from 'Betslip/helpers';
import { clearBetslip } from 'sportsbook-betslip-thunks';
import {
  getStatus,
  getBetslipInited,
  getSubmitResponse,
  getBetslipSingles,
  getBetslipMultiples,
  getBetslipReference,
  getCurrentTab,
} from 'sportsbook-betslip-selector';

import FullBetslipContainer from './full_betslip_container';

const { RightSidebar } = window;

const FullBetslipRedux = () => {
  const dispatch = useDispatch();
  const betslipStatus = useSelector(getStatus, isEqual);
  const submitResponse = useSelector(getSubmitResponse, isEqual);
  const singles = useSelector(getBetslipSingles, isEqual);
  const multiples = useSelector(getBetslipMultiples, isEqual);
  const betslipReference = useSelector(getBetslipReference, isEqual);
  const currentTab = useSelector(getCurrentTab, isEqual);
  const rightSidebar = useSelector((state) => state.rightSidebar);
  const inited = useSelector(getBetslipInited, isEqual);
  const hidePanel = () => {
    RightSidebar.hide();
    clearAllBodyScrollLocks();
    if (submitResponse && submitResponse.success) dispatch(clearBetslip());
  };
  const visible = rightSidebar?.visible || isDesktop();

  return (
    <FullBetslipContainer
      hidePanel={hidePanel}
      betslipReference={betslipReference}
      betslipStatus={betslipStatus}
      submitResponse={submitResponse}
      isEmptyBetslip={singles.length === 0 && multiples.length === 0}
      currentTab={currentTab}
      visible={visible}
      inited={inited}
      hasAnyLineDistanceSingle={isAnyWithLineDistance(singles)}
    />
  );
};

export default withErrorBoundary(FullBetslipRedux, { FallbackComponent: () => <div /> });
