import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { withErrorBoundary } from 'bv-error-boundary';
import { hideMiniBetslip } from 'sportsbook-betslip-duck';
import { clearBetslip } from 'sportsbook-betslip-thunks';
import { isAnyWithLineDistance } from 'Betslip/helpers';
import {
  makeGetBetslipOutcomes,
  getShowMiniBetslip,
  getBetslipReference,
  getStatus,
  getSubmitResponse,
  getBetslipSingles,
  getBetslipMultiples,
} from 'sportsbook-betslip-selector';

import PanelContainer from './panel_container';

const getBetslipOutcomes = makeGetBetslipOutcomes();

const PanelRedux = () => {
  const dispatch = useDispatch();
  const showMiniBetslip = useSelector(getShowMiniBetslip, isEqual);
  const betslipReference = useSelector(getBetslipReference, isEqual);
  const betslipStatus = useSelector(getStatus, isEqual);
  const submitResponse = useSelector(getSubmitResponse, isEqual);
  const outcomes = useSelector(getBetslipOutcomes, isEqual);
  const singles = useSelector(getBetslipSingles, isEqual);
  const multiples = useSelector(getBetslipMultiples, isEqual);

  // swiping down the mini-betslip when it's showing a receit should
  // clear the betslip
  const hidePanel = () => {
    dispatch(hideMiniBetslip());
    if (submitResponse && submitResponse.success) dispatch(clearBetslip());
  };

  // this flag will be turned to true when you are adding a bet to the betslip and
  // had nothing before and you now have exactly one outcome in the state
  if (!showMiniBetslip) return null;

  return (
    <PanelContainer
      betslipReference={betslipReference}
      betslipStatus={betslipStatus}
      hidePanel={hidePanel}
      submitResponse={submitResponse}
      outcomes={outcomes}
      isEmptyBetslip={singles.length === 0 && multiples.length === 0}
      hasAnyLineDistanceSingle={isAnyWithLineDistance(singles)}
    />
  );
};
export default withErrorBoundary(PanelRedux, { FallbackComponent: () => <div /> });
