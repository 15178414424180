import PropTypes from 'prop-types';
import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';

const PotentialReturnsView = ({
  isEW, isSP, stakeValue, potentialReturns, placedReturns,
}) => (
  <>
    {isSP && stakeValue > 0 && (
      <div className="returns">
        {t('betslip.potential_returns.label', { returns: t('na') })}
      </div>
    )}
    {potentialReturns > 0 && (
      <>
        <div className="returns">
          {t('betslip.potential_returns.label', {
            returns: toCurrency(potentialReturns, { decimalPlaces: 2 }),
          })}
        </div>
        {
          isEW
            ? (
              <div className="returns">
                {
                  t('betslip.potential_returns.if_placed', {
                    returns: toCurrency(placedReturns, { decimalPlaces: 2 }),
                  })
                }
              </div>
            )
            : ''
        }
      </>
    )}
  </>
);

PotentialReturnsView.propTypes = {
  isEW: PropTypes.bool.isRequired,
  isSP: PropTypes.bool.isRequired,
  stakeValue: PropTypes.number.isRequired,
  potentialReturns: PropTypes.number.isRequired,
  placedReturns: PropTypes.isRequired,
};

export default PotentialReturnsView;
