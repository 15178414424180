import { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BetslipContext from 'Betslip/context';
import SingleHeader from 'Betslip/components/singles/single_header';
import SinglePrice from 'Betslip/components/singles/single_price';
import { BB2Description, StandardDescription } from 'Betslip/components/singles/single_description';
import Legs from 'Betslip/components/common/legs';
import StakeInput from 'Betslip/components/stake_input';
import PotentialReturns from 'Betslip/components/potential_returns';
import BonusSignPosting from 'Betslip/components/bonus_sign_posting';
import {
  getStakeId, hasPriceChanged, hasLineChanged, hasLineFollowed, isSuspended, isHidden,
} from 'Betslip/helpers';

const SingleView = ({
  single,
  outcome,
  outcomeId,
  removeFromBetslip,
  keyboardRef,
  currentTab,
}) => {
  const { betslipType, tabsEnabled } = useContext(BetslipContext);
  const inputDisabled = isSuspended(outcome) || isHidden(outcome);
  const simplifiedViewForMultiple = tabsEnabled && currentTab !== 'singles';
  const isBB2 = !!single.outcomeLegs.length;
  const Description = isBB2 ? BB2Description : StandardDescription;

  const className = classnames(
    'betslip-single',
    {
      'bet--suspended': isSuspended(outcome),
      'bet--inactive': isHidden(outcome),
      'line-changed': hasLineChanged(single) || hasLineFollowed(single),
      'price-changed': hasPriceChanged(single) || hasLineChanged(single) || hasLineFollowed(single),
      'bb-single': isBB2,
    },
  );

  return (
    <div className={className}>
      <SingleHeader outcomeId={outcomeId} />
      <button
        className="betslip-single__close-button bvs-icon is-close"
        type="button"
        onClick={removeFromBetslip}
      />
      <div className="betslip-single__container">
        <Description
          {...single}
          stakeId={getStakeId(single)}
          hideEW={simplifiedViewForMultiple}
        />
        <div className="betslip-single__bet-info">
          <div className="odds-input-wrapper">
            <SinglePrice outcomeId={outcomeId} />
            {!simplifiedViewForMultiple && (
              <StakeInput bet={single} keyboardRef={keyboardRef} disabled={inputDisabled} />
            )}
          </div>
          <div className="extra-fields">
            {betslipType === 'full-betslip' && !simplifiedViewForMultiple && (
              <PotentialReturns bet={single} betType="single" />
            )}
            {single.bonusFundsEligible && !simplifiedViewForMultiple && <BonusSignPosting />}
          </div>
        </div>
      </div>
      {isBB2 && <Legs legs={single.outcomeLegs} />}
    </div>
  );
};

SingleView.propTypes = {
  outcomeId: PropTypes.isRequired,
  outcome: PropTypes.isRequired,
  single: PropTypes.isRequired,
  removeFromBetslip: PropTypes.isRequired,
  keyboardRef: PropTypes.isRequired,
  currentTab: PropTypes.string.isRequired,
};

export default SingleView;
