import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isDesktop } from 'bv';
import { useLoggedIn } from 'bv-hooks';
import { setComponent } from 'right-sidebar';
import { getCashoutBetNumber } from 'Betslip/selector';

const EmptyBetslipView = () => {
  const desktop = isDesktop();
  const loggedIn = useLoggedIn();
  const cashoutBetsNumber = useSelector(getCashoutBetNumber);

  useEffect(() => {
    if (desktop && loggedIn && cashoutBetsNumber !== 0) {
      setComponent('cashout');
    }
  }, [desktop, loggedIn, cashoutBetsNumber]);

  return null;
};

export default EmptyBetslipView;
