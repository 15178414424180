import { useContext } from 'react';
import { useDispatch, useStore } from 'react-redux';
import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { useEventBus, useFeature } from 'bv-hooks';
import { disableSingleEWStatus, updateSinglesInplayStatus } from 'sportsbook-betslip-duck';
import { followLineChange } from 'Betslip/thunks';
import BetslipContext from 'Betslip/context';
import SingleView from './single_view';

const SingleContainer = ({
  single,
  outcome,
  outcomeId,
  removeFromBetslip,
  keyboardRef,
  currentTab,
}) => {
  const [followLineChanges, loaded] = useFeature('follow_line_changes');
  const { betslipType } = useContext(BetslipContext);
  const dispatch = useDispatch();
  const store = useStore();
  const isFullBetSlip = betslipType === 'full-betslip';

  useEventBus(
    `/events/${outcome.eventId}/market-types/${outcome.mtid}/periods/${outcome.pid}/mbl-distance/${outcome.outcomeLineDistance}/brand/${bvVar('brandId')}`,
    (_, { body: data }) => {
      followLineChange({
        dispatch,
        getState: store.getState,
        outcomeId: outcome.id,
        outcomeKey: outcome.key,
        lineChangePush: data,
      });
    },
    [
      outcome.id,
      outcome.key,
      outcome.eventId,
      outcome.mtid,
      outcome.pid,
      outcome.outcomeLineDistance,
      loaded,
      followLineChanges,
    ],
    {
      shouldRegister: Number.isInteger(outcome.outcomeLineDistance) && followLineChanges && loaded
        && isFullBetSlip,
    },
  );

  useEventBus(
    `/event/${single.eventId}/inplay`,
    (_, { body }) => {
      dispatch(updateSinglesInplayStatus(body));
    },
    [single.eventId],
    { shouldRegister: isFullBetSlip },
  );

  useEventBus(
    `/event/${single.eventId}/marketsummary/${single.marketTypeId}`,
    (_, { body: event }) => {
      (event.u || []).forEach(({ id, ptn }) => {
        if (id === single.marketId && ptn === 1) {
          store.dispatch(
            disableSingleEWStatus({ outcomeId: single.outcomeId }),
          );
        }
      });
    },
    [single.eventId, single.hasEW, single.marketTypeId],
    { shouldRegister: single.hasEW && isFullBetSlip },
  );

  return (
    <SingleView
      keyboardRef={keyboardRef}
      outcomeId={outcomeId}
      outcome={outcome}
      single={single}
      removeFromBetslip={removeFromBetslip}
      currentTab={currentTab}
    />
  );
};

SingleContainer.propTypes = {
  outcomeId: PropTypes.isRequired,
  outcome: PropTypes.isRequired,
  single: PropTypes.isRequired,
  removeFromBetslip: PropTypes.isRequired,
  keyboardRef: PropTypes.isRequired,
  currentTab: PropTypes.string.isRequired,
};

export default SingleContainer;
