import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import StakeRow from './stake_row';
import ReturnRows from './return_rows';
import PriceRow from './price_row';
import OutcomeDetails from './outcome_details';

const SingleReceipt = ({
  single, outcome, fullDetails,
}) => (
  <div className="betslip-single">
    <div className="betslip-single__container">
      <div className="betslip-single__description">
        {fullDetails && (
          <div className="betslip-single__type-label">
            {t('betslip.receipt.single_label')}
            {single.ew && (
              <span>
                {' - '}
                {t('betslip.receipt.each_way')}
              </span>
            )}
          </div>
        )}
        <OutcomeDetails outcome={outcome} fullDetails={fullDetails} />
        <StakeRow promocash={single.bonusFunds} totalStake={single.totalStake} />
        <PriceRow
          selection={single}
          fractionalEnhancedOdds={single.fractionalEnhancedPrice}
        />
        <ReturnRows potentialReturns={single.potentialReturns} />
      </div>
    </div>
  </div>
);

SingleReceipt.propTypes = {
  single: PropTypes.isRequired,
  outcome: PropTypes.isRequired,
  fullDetails: PropTypes.isRequired,
};

export default SingleReceipt;
