import {
  useRef, useEffect, useState, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import classnames from 'classnames';
import { useFeature } from 'bv-hooks';
import { enable as enableScroll, disable as disableScroll } from 'bv-services/body-scroll-manager';
import { isDesktop, v as bvVar } from 'bv';
import { showBetslipAcceptAllLineChanges, showBetslipAcceptAllPriceChanges } from 'global-config';
import { BETSLIP_STATUS } from 'sportsbook-betslip-duck';
import Singles from 'Betslip/components/singles';
import Multiples from 'Betslip/components/multiples';
import SubmitButton from 'Betslip/components/submit_button';
import PromocashToggle from 'Betslip/components/promocash_toggle';
import AcceptAllPriceChangesToggle from 'AcceptAllPriceChangesToggle';
import AcceptAllLineChangesToggle from 'AcceptAllLineChangesToggle';
import Summary from 'Betslip/components/full_betslip/summary';
import EmptyBetslipView from 'Betslip/components/full_betslip/empty_betslip_view';
import PanelHandle from 'Betslip/components/panel_handle';
import Betreceipt from 'Betslip/components/full_betslip/betreceipt';
import BetslipContext from 'Betslip/context';
import GeneralError from 'Betslip/components/errors/general_error';
import PanicButton from 'PanicButton';
import PayoutDisclaimer from 'Betslip/components/payout_disclaimer';
import CrackTheCode from 'Betslip/components/crack_the_code';
import Tabs from './tabs';

const FullBetslipView = ({
  betslipReference,
  betslipStatus,
  acceptablePriceChangePct,
  acceptAllLineChanges,
  submitResponse,
  isEmptyBetslip,
  hidePanel,
  currentTab,
  visible,
  inited,
  hasAnyLineDistanceSingle,
}) => {
  const [mounted, setMounted] = useState(false);
  const keyboardRef = useRef();
  const panelRef = useRef();
  const rightSidebarNode = panelRef.current ? panelRef.current.parentNode.parentNode : null;
  const rightSidebarScrollableRef = useRef(null);
  const [followLineChangesFF, followLineChangesFFLoaded] = useFeature('follow_line_changes');

  const topClass = classnames(
    'betslip betslip--full',
    {
      'betslip--disabled': betslipStatus !== BETSLIP_STATUS.READY,
      'betslip--desktop': isDesktop(),
    },
  );

  const hasSubmitSuccess = submitResponse && submitResponse.success;
  const { tabsEnabled } = useContext(BetslipContext);

  useEffect(() => setMounted(true), []);

  // normally right-sidebar-scrollable would be scroll-locked when the sidebar gets visible
  // problem is that it's locked by dom node. Once react removes/adds the sidebar node the lock
  // gets lost and this breaks scrolling on re-used bets after successful submit
  //
  // we also can't just lock every time this component renders because on mobi the full betslip
  // is rendered (but hidden from view) until the user clicks on the floating-nav, so it would
  // lock scroll-lock the page on pageload
  useEffect(() => {
    if (rightSidebarScrollableRef.current && visible) {
      const node = rightSidebarScrollableRef.current;
      disableScroll(node);
      return (() => {
        enableScroll(node);
      });
    }
    return undefined;
  }, [rightSidebarScrollableRef.current, visible]);

  return (
    <div id="full-betslip" className={topClass} ref={panelRef}>
      { mounted && <PanelHandle hidePanel={hidePanel} panelNode={rightSidebarNode} animateProp="top" /> }
      { bvVar('crackTheCode').standard && (
        <CrackTheCode betslipReference={betslipReference} isEmptyBetslip={isEmptyBetslip} />
      )}
      <PanicButton source="betslip-top" />
      { (isEmpty(submitResponse) || submitResponse.success === false) && (
        isEmptyBetslip
          ? (
            <>
              { inited && tabsEnabled && <Tabs /> }
              { inited && <EmptyBetslipView /> }
            </>
          )
          : (
            <>
              <GeneralError />
              { tabsEnabled && <Tabs /> }
              <div className="betslip__bet-list right-sidebar-scrollable" ref={rightSidebarScrollableRef}>
                <Singles keyboardRef={keyboardRef} />
                {(!tabsEnabled || (tabsEnabled && currentTab !== 'singles')) && (
                  <Multiples keyboardRef={keyboardRef} />
                )}
              </div>
              <div className="betslip__details">
                <PayoutDisclaimer hidePanel={hidePanel} />
                <div className="betslip__details__toggles">
                  <PromocashToggle />
                  { showBetslipAcceptAllPriceChanges
                    && (
                    <AcceptAllPriceChangesToggle
                      acceptablePriceChangePct={acceptablePriceChangePct}
                    />
                    )}
                  { showBetslipAcceptAllLineChanges
                    && hasAnyLineDistanceSingle
                    && followLineChangesFF
                    && followLineChangesFFLoaded
                    && <AcceptAllLineChangesToggle acceptAllLineChanges={acceptAllLineChanges} />}
                </div>
                <Summary />
                <div className="betslip__stake-button-wrapper">
                  <SubmitButton />
                </div>
                <div className="betslip__keypad-container" ref={keyboardRef} />
              </div>
            </>
          )
      )}
      { betslipStatus === BETSLIP_STATUS.READY && hasSubmitSuccess && visible && (
        <Betreceipt />
      )}
      <PanicButton source="betslip-bottom" />
    </div>
  );
};

FullBetslipView.propTypes = {
  betslipReference: PropTypes.string.isRequired,
  betslipStatus: PropTypes.string.isRequired,
  acceptablePriceChangePct: PropTypes.number,
  acceptAllLineChanges: PropTypes.bool,
  submitResponse: PropTypes.instanceOf(Object).isRequired,
  isEmptyBetslip: PropTypes.bool.isRequired,
  hidePanel: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  inited: PropTypes.bool.isRequired,
  hasAnyLineDistanceSingle: PropTypes.bool.isRequired,
};

FullBetslipView.defaultProps = {
  acceptablePriceChangePct: null,
  acceptAllLineChanges: false,
};

export default FullBetslipView;
