import PropTypes from 'prop-types';

import { handledBetIssues } from 'Betslip/helpers';

const MultipleHeaderView = ({ betIssue }) => {
  const errorLevel = betIssue && betIssue.type && handledBetIssues.includes(betIssue.type) ? 'is-danger' : 'is-warning';

  return (
    <div className={`betslip__status ${errorLevel}`}>
      <span className={`bvs-icon ${errorLevel}`} />
      <span>{betIssue.errorMessage}</span>
    </div>
  );
};

MultipleHeaderView.propTypes = {
  betIssue: PropTypes.isRequired,
};

export default MultipleHeaderView;
