import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import {
  getStakes,
  getUsePromocash,
  getRemoveStakeForBonusBet,
  getTaxDeductPrc,
} from 'sportsbook-betslip-selector';
import {
  totalPotentialReturns, totalEnhancedReturns, netTotalPotentialReturns, totalTaxDeduct,
} from 'Betslip/services/potential_returns';
import BetslipContext from 'Betslip/context';
import { getEnhancedOutcomesById } from 'EnhanedOutcomeOffers/selectors';
import totalStake from 'Betslip/services/total_stake';
import filteredSingles from 'Betslip/services/filtered_singles';
import filteredMultiples from 'Betslip/services/filtered_multiples';

import TaxInfoDialogContainer from './tax_info_dialog_container';

const TaxInfoDialog = () => {
  const { betslipType } = useContext(BetslipContext);
  const stakes = useSelector(getStakes, isEqual);
  const removeStakeForBonusBet = useSelector(getRemoveStakeForBonusBet, isEqual);
  const usePromocash = useSelector(getUsePromocash, isEqual);
  const enhancedOutcomesById = useSelector(getEnhancedOutcomesById, isEqual);
  const taxDeductPrc = useSelector(getTaxDeductPrc, isEqual);

  const { singles } = filteredSingles({ removeSuspended: true });
  const { multiples } = filteredMultiples({ removeSuspended: true });

  if (taxDeductPrc === 0) return null;

  const grossReturns = totalPotentialReturns({
    singles,
    multiples,
    enhancedOutcomesById,
    removeStakeForBonusBet,
    usePromocash,
    stakes,
  });

  const netReturns = netTotalPotentialReturns({
    singles,
    multiples,
    enhancedOutcomesById,
    removeStakeForBonusBet,
    usePromocash,
    stakes,
  }, taxDeductPrc);

  const enhancedReturns = totalEnhancedReturns({
    singles,
    enhancedOutcomesById,
    stakes,
    usePromocash,
    removeStakeForBonusBet,
  });

  const taxDeductValue = totalTaxDeduct({
    singles,
    multiples,
    enhancedOutcomesById,
    removeStakeForBonusBet,
    usePromocash,
    stakes,
  }, taxDeductPrc);

  return (
    <TaxInfoDialogContainer
      betslipType={betslipType}
      totalStake={totalStake(singles, multiples, stakes)}
      potentialReturns={grossReturns}
      enhancedReturns={enhancedReturns}
      taxDeductPrc={taxDeductPrc}
      taxDeductValue={taxDeductValue}
      netReturns={netReturns}
    />
  );
};

export default TaxInfoDialog;
