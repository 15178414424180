import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { isEqual, indexBy } from 'underscore';
import { getBetslipSingles, getCurrentTab, makeGetBetslipOutcomes } from 'sportsbook-betslip-selector';
import { singlesFilter, isSuspended } from 'Betslip/helpers';
import BetslipContext from 'Betslip/context';

const getBetslipOutcomes = makeGetBetslipOutcomes();

export default ({ removeSuspended } = { removeSuspended: false }) => {
  const { tabsEnabled } = useContext(BetslipContext);
  const currentTab = useSelector(getCurrentTab);
  const outcomesById = indexBy(useSelector(getBetslipOutcomes, isEqual), 'id');
  let allSingles = useSelector(getBetslipSingles, isEqual);
  if (removeSuspended) {
    allSingles = allSingles.filter(
      (s) => outcomesById[s.outcomeId] && !isSuspended(outcomesById[s.outcomeId]),
    );
  }

  const singles = allSingles.filter(singlesFilter(tabsEnabled, currentTab));

  return { singles, allSingles };
};
