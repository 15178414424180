import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import BetreceiptHeader from 'Betslip/components/betreceipt/header';
import BetreceiptButtons from 'Betslip/components/betreceipt/buttons';
import SingleReceipt from 'Betslip/components/betreceipt/single_receipt';
import CrackTheCode from 'Betslip/components/betreceipt/crack_the_code';

const BetreceiptView = ({
  id, onClearAndClose, onReuse, canReuse, outcome, single,
}) => (
  <div className="betslip-receipt-wrapper">
    {id && bvVar('crackTheCode').standard && <CrackTheCode id={id} />}
    <BetreceiptHeader />
    <div className="betslip__bet-list">
      <SingleReceipt
        single={single}
        outcome={outcome}
        fullDetails={false}
      />
    </div>
    <BetreceiptButtons canReuse={canReuse} onReuse={onReuse} onClearAndClose={onClearAndClose} />
  </div>
);

BetreceiptView.propTypes = {
  id: PropTypes.number.isRequired,
  onClearAndClose: PropTypes.func.isRequired,
  onReuse: PropTypes.func.isRequired,
  canReuse: PropTypes.bool.isRequired,
  outcome: PropTypes.isRequired,
  single: PropTypes.isRequired,
};

export default BetreceiptView;
