import { useState } from 'react';
import PropTypes from 'prop-types';

import TaxInfoDialogView from './tax_info_dialog_view';

const { CUSTOMER_CONSENT_URLS } = window.VCSTATE;

const TaxInfoDialogContainer = ({
  betslipType,
  potentialReturns,
  enhancedReturns,
  taxDeductPrc,
  taxDeductValue,
  netReturns,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const onToggle = () => {
    setShowDialog(!showDialog);
  };

  const termsAndConditionsUrl = (
    new URL(CUSTOMER_CONSENT_URLS.terms_and_conditions, document.location)
  ).toString();

  return (
    <TaxInfoDialogView
      onToggle={onToggle}
      showDialog={showDialog}
      betslipType={betslipType}
      potentialReturns={potentialReturns}
      enhancedReturns={enhancedReturns}
      taxDeductPrc={taxDeductPrc}
      taxDeductValue={taxDeductValue}
      netReturns={netReturns}
      termsAndConditionsUrl={termsAndConditionsUrl}
    />
  );
};

TaxInfoDialogContainer.propTypes = {
  betslipType: PropTypes.isRequired,
  potentialReturns: PropTypes.number.isRequired,
  enhancedReturns: PropTypes.number.isRequired,
  taxDeductPrc: PropTypes.number.isRequired,
  taxDeductValue: PropTypes.number.isRequired,
  netReturns: PropTypes.number.isRequired,
};

export default TaxInfoDialogContainer;
