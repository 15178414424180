import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { getStakeId, getStakeValue, mergeEnhancedOutcomesWithSingles } from 'Betslip/helpers';
import { getEnhancedOutcomesById } from 'EnhanedOutcomeOffers/selectors';
import { totalPotentialReturns, totalPlacedReturns, isSomeSingleNonCalculable } from 'Betslip/services/potential_returns';
import {
  getStake,
  getStakes,
  getUsePromocash,
  getRemoveStakeForBonusBet,
  getBetslipSingles,
} from 'sportsbook-betslip-selector';

import PotentialReturnsView from './potential_returns_view';

const PotentialReturnsRedux = ({ betType, bet }) => {
  const stakes = useSelector(getStakes, isEqual);
  const stakeId = getStakeId(bet);
  const stake = useSelector((state) => getStake(state, stakeId), isEqual);
  const removeStakeForBonusBet = useSelector(getRemoveStakeForBonusBet, isEqual);
  const usePromocash = useSelector(getUsePromocash, isEqual);
  const enhancedOutcomesById = useSelector(getEnhancedOutcomesById, isEqual);
  const singles = useSelector(getBetslipSingles, isEqual);
  const anySpSingle = isSomeSingleNonCalculable(singles, stakes);

  const returnsOpts = {
    ...({ singles: betType === 'single' ? [bet] : [] }),
    ...({
      multiples: betType === 'multiple' ? [bet] : [],
      singlesForMultiplesCalculations: betType === 'multiple' ? singles : [],
    }),
    enhancedOutcomesById: mergeEnhancedOutcomesWithSingles(enhancedOutcomesById, [bet]),
    removeStakeForBonusBet,
    usePromocash,
    stakes,
  };

  const potentialReturns = totalPotentialReturns(returnsOpts);
  const placedReturns = totalPlacedReturns({ ...returnsOpts, usePlacedOdds: true });
  const stakeValue = getStakeValue(stake);

  return (
    <PotentialReturnsView
      isEW={stake.ews}
      potentialReturns={potentialReturns}
      placedReturns={placedReturns}
      isSP={betType === 'single' ? stake.sp : anySpSingle || stake.sp}
      stakeValue={stakeValue}
    />
  );
};

PotentialReturnsRedux.propTypes = {
  betType: PropTypes.isRequired,
  bet: PropTypes.isRequired,
};

export default PotentialReturnsRedux;
