import PropTypes from 'prop-types';

import EwToggle from 'Betslip/components/ew_toggle';

const Terms = ({
  ewAllowed,
  hideEW,
  stakeId,
  placeTermsDescription,
}) => {
  if (!ewAllowed || hideEW) {
    return null;
  }

  return (
    <div className="place-terms-description">
      <EwToggle stakeId={stakeId} />
      <span>{placeTermsDescription?.replace(/^E\/W/, '')}</span>
    </div>
  );
};

Terms.propTypes = {
  stakeId: PropTypes.number.isRequired,
  placeTermsDescription: PropTypes.string.isRequired,
  ewAllowed: PropTypes.number.isRequired,
  hideEW: PropTypes.bool,
};

Terms.defaultProps = {
  hideEW: false,
};

export default Terms;
