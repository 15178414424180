import PropTypes from 'prop-types';

const Event = ({
  showMeetingDescription,
  meetingDescription,
  eventDescription,
}) => (
  <div className="betslip-single__event">
    {showMeetingDescription && meetingDescription}
    {' '}
    {eventDescription}
  </div>
);

Event.propTypes = {
  showMeetingDescription: PropTypes.bool.isRequired,
  meetingDescription: PropTypes.string.isRequired,
  eventDescription: PropTypes.string.isRequired,
};

export default Event;
