import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const ConfirmModalView = ({ handleNoBtnClick, handleYesBtnClick }) => (
  <RenderInBody>
    <Modal
      info
      icon
      className="betslip-tax-info-info"
      title={t('accept_all_price_changes_toggle.title')}
      actions={[
        {
          secondary: true,
          label: t('accept_all_price_changes_toggle.confirm_button'),
          onClick: handleYesBtnClick,
        },
        {
          secondary: true,
          inverse: true,
          label: t('accept_all_price_changes_toggle.cancel_button'),
          onClick: handleNoBtnClick,
        },
      ]}
    >
      <p
        dangerouslySetInnerHTML={
          {
            __html: (
              sanitize(
                t('accept_all_price_changes_toggle.confirm_body').replace(/\n/g, '<br />'),
              )
            ),
          }
        }
      />
    </Modal>
  </RenderInBody>
);

ConfirmModalView.propTypes = {
  handleNoBtnClick: PropTypes.func.isRequired,
  handleYesBtnClick: PropTypes.func.isRequired,
};

export default ConfirmModalView;
