import PropTypes from 'prop-types';
import Single from './single';

const SinglesView = ({ singleOutcomeIds, keyboardRef }) => (
  <>
    {singleOutcomeIds.map(
      (outcomeId) => <Single keyboardRef={keyboardRef} outcomeId={outcomeId} />,
    )}
  </>
);

SinglesView.propTypes = {
  singleOutcomeIds: PropTypes.isRequired,
  keyboardRef: PropTypes.isRequired,
};

export default SinglesView;
