import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import StakeRow from './stake_row';
import PriceRow from './price_row';
import ReturnRows from './return_rows';
import OutcomeDetails from './outcome_details';

const MultipleReceipt = ({ multiple, outcomes }) => (
  <div className="betslip-single">
    <div className="betslip-single__container">
      <div className="betslip-single__description">
        <div className="betslip-single__type-label">
          {multiple.description}
          {multiple.ew && (
            <span>
              {' - '}
              {t('betslip.receipt.each_way')}
            </span>
          )}
        </div>
        {Object.values(outcomes).map((outcome) => (
          <OutcomeDetails outcome={outcome} />
        ))}
        <StakeRow promocash={multiple.bonusFunds} totalStake={multiple.totalStake} />
        <PriceRow selection={multiple} fractionalEnhancedOdds={false} />
        <ReturnRows potentialReturns={multiple.potentialReturns} />
      </div>
    </div>
  </div>
);

MultipleReceipt.propTypes = {
  multiple: PropTypes.isRequired,
  outcomes: PropTypes.isRequired,
};

export default MultipleReceipt;
