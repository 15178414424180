import { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import PropTypes from 'prop-types';
import { getStake } from 'sportsbook-betslip-selector';
import { setStakeValue } from 'sportsbook-betslip-duck';
import { get as lsGet, set as lsSet } from 'bv-local-storage';
import { getStakeId } from 'Betslip/helpers';
import BetslipContext from 'Betslip/context';

import StakeInputView from './stake_input_view';

const StakeInputRedux = ({
  bet, keyboardRef, disabled,
}) => {
  const dispatch = useDispatch();
  const stakeId = getStakeId(bet);
  const stake = useSelector((state) => getStake(state, stakeId), isEqual);
  const { betslipType } = useContext(BetslipContext);
  const { predefinedStake } = bet;
  const lsStakeKey = `stakes-v2-${stakeId}`;

  const setStake = (value) => {
    lsSet(lsStakeKey, { ...stake, value });
    dispatch(setStakeValue(stakeId, value));
  };

  useEffect(() => {
    const storedStake = lsGet(lsStakeKey);
    if (!storedStake && predefinedStake) {
      setStake(predefinedStake);
    }
  }, [predefinedStake]);

  return (
    <StakeInputView
      isEW={stake.ews}
      stake={stake}
      setStake={setStake}
      keyboardRef={keyboardRef}
      sportId={bet.sportId}
      stakeId={stakeId}
      betslipType={betslipType}
      disabled={disabled}
    />
  );
};

StakeInputRedux.propTypes = {
  bet: PropTypes.isRequired,
  keyboardRef: PropTypes.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default StakeInputRedux;
