import PropTypes from 'prop-types';

import BetslipTab from './tab_view';

const TabsView = ({ tabs, setTab }) => (
  <div className="betslip__tabs">
    {tabs.map((tab) => <BetslipTab {...tab} setTab={setTab} />)}
  </div>
);

TabsView.propTypes = {
  tabs: PropTypes.isRequired,
  setTab: PropTypes.isRequired,
};

export default TabsView;
