import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { showMeetingSportIds } from 'Betslip/helpers';
import Legs from 'Betslip/components/common/legs';
import OutcomeDescription from './outcome_desctiption';
import MarketDescription from './market_description';

const OutcomeDetails = ({
  outcome: {
    lineChanged,
    outcomeLegs,
    outcomeDescription,
    requestedLineExtraInfo,
    marketDescription,
    periodDescription,
    meetingDescription,
    eventDescription,
    inRunning,
    sportId,
  },
  fullDetails = true,
}) => (
  <div className="betslip-single__outcome-details">
    {(fullDetails || lineChanged) && (
      <>
        {outcomeLegs.length ? (
          <Legs legs={outcomeLegs} />
        ) : (
          <OutcomeDescription
            description={outcomeDescription}
            lineChanged={lineChanged}
          />
        )}
        <MarketDescription
          description={marketDescription}
          lineChanged={lineChanged}
          requestedLineExtraInfo={requestedLineExtraInfo}
        />
      </>
    )}
    {fullDetails && (
      <>
        <div className="betslip-single__period">{periodDescription}</div>
        <div className="betslip-single__event">
          {showMeetingSportIds.includes(sportId) && meetingDescription}
          {' '}
          {eventDescription}
        </div>
        {inRunning && (
          <div className="betslip-single__in-running">
            {t('in_play_bets_indicator.placed_in_play')}
          </div>
        )}
      </>
    )}
  </div>
);

OutcomeDetails.propTypes = {
  outcome: PropTypes.shape({
    outcomeDescription: PropTypes.string.isRequired,
    marketDescription: PropTypes.string.isRequired,
    periodDescription: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    meetingDescription: PropTypes.string.isRequired,
    eventDescription: PropTypes.string.isRequired,
    inRunning: PropTypes.bool.isRequired,
    outcomeLegs: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string.isRequired,
    })).isRequired,
    requestedLineExtraInfo: PropTypes.string.isRequired,
    lineChanged: PropTypes.string.isRequired,
  }).isRequired,
  fullDetails: PropTypes.bool.isRequired,
};

export default OutcomeDetails;
