import { useContext } from 'react';
import PropTypes from 'prop-types';
import { get as lsGet } from 'bv-local-storage';
import { isSmall } from 'bv-helpers/screen';
import { hasSportsbook, showQuickbetDefault } from 'global-config';
import context from 'Betslip/context';

import PanelView from './panel_view';

const PanelContainer = ({
  betslipStatus,
  hidePanel,
  betslipReference,
  submitResponse,
  outcomes,
  isEmptyBetslip,
  hasAnyLineDistanceSingle,
}) => {
  const preferenceDisabled = lsGet('hide_quickbet');
  const { settings } = useContext(context);

  if (hasSportsbook) {
    if (!isSmall()) return null;
    if (preferenceDisabled) return null;
    if (preferenceDisabled === null && !showQuickbetDefault) return null;
  }

  return (
    <PanelView
      betslipReference={betslipReference}
      betslipStatus={betslipStatus}
      hidePanel={hidePanel}
      acceptablePriceChangePct={settings.acceptablePriceChangePct}
      acceptAllLineChanges={settings.acceptLineChange}
      submitResponse={submitResponse}
      outcomes={outcomes}
      isEmptyBetslip={isEmptyBetslip}
      hasAnyLineDistanceSingle={hasAnyLineDistanceSingle}
    />
  );
};

PanelContainer.propTypes = {
  betslipReference: PropTypes.string.isRequired,
  betslipStatus: PropTypes.string.isRequired,
  hidePanel: PropTypes.func.isRequired,
  submitResponse: PropTypes.isRequired,
  outcomes: PropTypes.isRequired,
  isEmptyBetslip: PropTypes.bool.isRequired,
  hasAnyLineDistanceSingle: PropTypes.bool.isRequired,
};

export default PanelContainer;
