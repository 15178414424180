export const INITIAL_FETCH_SUCCESS = 'acceptAllPriceChangesToggle/INITIAL_FETCH_SUCCESS';
export const SETTINGS_UPDATE_START = 'acceptAllPriceChangesToggle/SETTINGS_UPDATE_START';
export const SETTINGS_UPDATE_SUCCESS = 'acceptAllPriceChangesToggle/SETTINGS_UPDATE_SUCCESS';
export const SETTINGS_UPDATE_ERROR = 'acceptAllPriceChangesToggle/SETTINGS_UPDATE_ERROR';
export const BET_PLACED_UPDATE = 'acceptAllPriceChangesToggle/BET_PLACED_UPDATE';

export const initialFetchSuccess = (value) => ({
  type: INITIAL_FETCH_SUCCESS,
  value,
});

export const settingsUpdateStart = () => ({
  type: SETTINGS_UPDATE_START,
});

export const settingsUpdateSuccess = (newValue) => ({
  type: SETTINGS_UPDATE_SUCCESS,
  newValue,
});

export const betPlacedUpdate = () => ({
  type: BET_PLACED_UPDATE,
});

export const settingsUpdateError = () => ({
  type: SETTINGS_UPDATE_ERROR,
});

const initialState = {
  initiated: false,
  initialValue: null,
  value: null,
  seenBetPlacement: false,
  loading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case INITIAL_FETCH_SUCCESS:
      return {
        ...state,
        ...(state.initiated === false
          ? { initiated: true, initialValue: action.value, value: action.value }
          : {}
        ),
      };
    case SETTINGS_UPDATE_START:
      return {
        ...state,
        loading: true,
      };
    case SETTINGS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        value: action.newValue,
      };
    case SETTINGS_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      };
    case BET_PLACED_UPDATE:
      return {
        ...state,
        seenBetPlacement: true,
      };
    default:
      return state;
  }
};
