import { useState } from 'react';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const PromocasInfoDialog = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      {show && (
        <RenderInBody>
          <Modal
            info
            title={t('betslip.bonus_eligibility_dialog.title')}
            className="betslip-bonus-eligibility-info"
            actions={[{
              label: t('betslip.bonus_eligibility_dialog.close_button'),
              className: 'bvs-button-alternative is-secondary',
              onClick: () => setShow(false),
            }]}
          >
            <p>
              {t('betslip.bonus_eligibility_dialog.body')}
            </p>
          </Modal>
        </RenderInBody>
      )}
      <span className="bvs-icon is-info-alt" onClick={() => setShow(true)} />
    </>
  );
};

export default PromocasInfoDialog;
