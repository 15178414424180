import PropTypes from 'prop-types';

import {
  hasPriceChanged, hasLineChanged, hasLineFollowed, isHidden, isSuspended,
} from 'Betslip/helpers';
import SinglePriceView from './single_price_view';

const SinglePriceContainer = ({
  single, outcome, enhancedOutcome, stake, toggleSP,
}) => {
  const enhancedPrice = enhancedOutcome
    && enhancedOutcome.active
    && enhancedOutcome.fractionalEnhancedOdds;

  return (
    <SinglePriceView
      priceChanged={hasPriceChanged(single) || hasLineChanged(single) || hasLineFollowed(single)}
      selectionLocked={isHidden(outcome) || isSuspended(outcome)}
      single={single}
      enhancedPrice={enhancedPrice}
      toggleSP={toggleSP}
      spSelected={stake.sp}
    />
  );
};

SinglePriceContainer.propTypes = {
  single: PropTypes.isRequired,
  outcome: PropTypes.isRequired,
  enhancedOutcome: PropTypes.isRequired,
  stake: PropTypes.isRequired,
  toggleSP: PropTypes.func.isRequired,
};

export default SinglePriceContainer;
