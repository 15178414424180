import PropTypes from 'prop-types';

import TabsView from './tabs_view';

const TabsContainer = ({
  singles,
  multiples,
  currentTab,
  setTab,
}) => {
  const hasSingles = singles.length > 0;
  const hasMultiples = multiples.filter((m) => m.multiplicity > 1).length > 0;
  const hasAcca = multiples.filter((m) => m.multiplicity === 1).length > 0;
  const tabs = [
    {
      type: 'singles',
      enabled: hasSingles,
      active: currentTab === 'singles',
    },
    {
      type: 'acca',
      enabled: hasAcca,
      active: currentTab === 'acca',
    },
    {
      type: 'multiples',
      enabled: hasMultiples,
      active: currentTab === 'multiples',
    },
  ];

  return <TabsView tabs={tabs} setTab={setTab} />;
};

TabsContainer.propTypes = {
  singles: PropTypes.isRequired,
  multiples: PropTypes.isRequired,
  currentTab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};

export default TabsContainer;
