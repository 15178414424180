import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import classnames from 'classnames';

const Tab = ({
  type, enabled, active, setTab,
}) => (
  <button
    data-tab={type}
    className={classnames('bvs-button-chip', { active, disabled: !enabled })}
    id="id-betslip_tabs_singles"
    type="button"
    disabled={!enabled}
    onClick={() => { setTab(type, true); }}
  >
    {t(`betslip.tabs.labels.${type}`)}
  </button>
);

Tab.propTypes = {
  enabled: PropTypes.isRequired,
  active: PropTypes.isRequired,
  type: PropTypes.isRequired,
  setTab: PropTypes.isRequired,
};

export default Tab;
