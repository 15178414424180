import { useContext } from 'react';
import PropTypes from 'prop-types';
import context from 'Betslip/context';

import PanelView from './full_betslip_view';

const FullBetslipContainer = ({
  hidePanel,
  betslipReference,
  betslipStatus,
  submitResponse,
  isEmptyBetslip,
  currentTab,
  visible,
  inited,
  hasAnyLineDistanceSingle,
}) => {
  const { settings } = useContext(context);

  return (
    <PanelView
      hidePanel={hidePanel}
      betslipReference={betslipReference}
      betslipStatus={betslipStatus}
      acceptablePriceChangePct={settings.acceptablePriceChangePct}
      acceptAllLineChanges={settings.acceptLineChange}
      submitResponse={submitResponse}
      isEmptyBetslip={isEmptyBetslip}
      currentTab={currentTab}
      visible={visible}
      inited={inited}
      hasAnyLineDistanceSingle={hasAnyLineDistanceSingle}
    />
  );
};

FullBetslipContainer.propTypes = {
  hidePanel: PropTypes.func.isRequired,
  betslipReference: PropTypes.string.isRequired,
  betslipStatus: PropTypes.string.isRequired,
  submitResponse: PropTypes.instanceOf(Object).isRequired,
  isEmptyBetslip: PropTypes.bool.isRequired,
  currentTab: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  inited: PropTypes.bool.isRequired,
  hasAnyLineDistanceSingle: PropTypes.bool.isRequired,
};

export default FullBetslipContainer;
