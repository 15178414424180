import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const { VCSTATE } = window;

const PayoutDisclaimer = ({ hidePanel }) => (
  <p className="betslip__details__payout-disclaimer">
    {t('javascript.betslip_payout_disclaimer')}
    {' '}
    <a
      href={VCSTATE.BETSLIP_PAYOUT_DISCLAIMER_URL}
      onClick={hidePanel}
    >
      {t('javascript.betslip_payout_more_info')}
      {' '}
      &gt;
    </a>
  </p>
);

PayoutDisclaimer.propTypes = {
  hidePanel: PropTypes.func.isRequired,
};

export default PayoutDisclaimer;
