import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector, useDispatch } from 'react-redux';
import { makeGetSingle, getStake, getUsePromocash } from 'sportsbook-betslip-selector';
import { setStakeSP } from 'sportsbook-betslip-duck';
import withOutcome from 'sportsbook-with-outcome';
import { getStakeId } from 'Betslip/helpers';

import SinglePriceContainer from './single_price_container';

const getSingle = makeGetSingle();

const SinglePriceRedux = ({ outcome, enhancedOutcome, outcomeId }) => {
  const dispatch = useDispatch();
  const single = useSelector((state) => getSingle(state, { outcomeId }), isEqual);
  const stake = useSelector((state) => getStake(state, getStakeId(single)), isEqual);
  const toggleSP = () => {
    dispatch(setStakeSP(getStakeId(single), !stake.sp));
  };
  const usePromocash = useSelector(getUsePromocash, isEqual);

  // Some promotions add fractionalEnhancedOdds to singles via betslip#acumulator urls
  // These will have their enhanced odds in the betslip payload and we need to pretend
  // that there was an enhanced outcome when displaying these
  const fakeEnhancedOutcome = single.fractionalEnhancedOdds
    ? { active: true, fractionalEnhancedOdds: single.fractionalEnhancedOdds } : null;
  const displayEnhancedOdds = !stake.ews && !usePromocash;

  return (
    <SinglePriceContainer
      single={single}
      outcome={outcome}
      enhancedOutcome={displayEnhancedOdds && (enhancedOutcome || fakeEnhancedOutcome)}
      stake={stake}
      toggleSP={toggleSP}
    />
  );
};

SinglePriceRedux.propTypes = {
  outcomeId: PropTypes.isRequired,
  outcome: PropTypes.isRequired,
  enhancedOutcome: PropTypes.isRequired,
};

export default withOutcome(SinglePriceRedux, { idAttribute: 'outcomeId' });
