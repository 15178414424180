import PropTypes from 'prop-types';
import classnames from 'classnames';
import MultiplePrice from 'Betslip/components/multiples/multiple_price';
import MultipleDescription from 'Betslip/components/multiples/multiple_description';
import PotentialReturns from 'Betslip/components/potential_returns';
import BetTypeInfo from 'Betslip/components/multiples/bet_type_info';
import StakeInput from 'Betslip/components/stake_input';
import BonusSignPosting from 'Betslip/components/bonus_sign_posting';
import MultipleHeader from 'Betslip/components/multiples/multiple_header';
import { getStakeId } from 'Betslip/helpers';

const MultipleView = ({
  multiple,
  showInfo,
  anySpSingle,
  onInfoClick,
  onInfoClose,
  keyboardRef,
  priceChanged,
}) => (
  <div className={classnames('betslip-single', { 'price-changed': priceChanged })}>
    <MultipleHeader stakeId={getStakeId(multiple)} />
    <div className="betslip-single__container">
      <MultipleDescription
        {...multiple}
        stakeId={getStakeId(multiple)}
        onInfoClick={onInfoClick}
      />
      <div className="betslip-single__bet-info">
        <div className="odds-input-wrapper">
          <MultiplePrice multiple={multiple} anySpSingle={anySpSingle} />
          <StakeInput bet={multiple} keyboardRef={keyboardRef} />
        </div>
        <div className="extra-fields">
          <PotentialReturns bet={multiple} betType="multiple" />
          {multiple.bonusFundsEligible && <BonusSignPosting />}
        </div>
      </div>
      {showInfo && (
        <BetTypeInfo
          description={multiple.description}
          winBetTypeId={multiple.winBetTypeId}
          onClose={onInfoClose}
        />
      )}
    </div>
  </div>
);

MultipleView.propTypes = {
  multiple: PropTypes.isRequired,
  onInfoClick: PropTypes.isRequired,
  onInfoClose: PropTypes.isRequired,
  showInfo: PropTypes.isRequired,
  anySpSingle: PropTypes.bool.isRequired,
  keyboardRef: PropTypes.isRequired,
  priceChanged: PropTypes.bool.isRequired,
};

export default MultipleView;
