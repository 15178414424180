import { checkOfferTimingValid } from './helpers';

export const getEnhnacedOutcomeByOutcomeId = (state, outcomeId) => {
  const enhancedOffer = state.enhanedOutcomeOffers.offers.find((offer) => {
    // if the offer has the enhanced outcome
    if (offer.enhancedOutcomes[outcomeId]) {
      return true;
    }
    return null;
  });

  if (enhancedOffer) {
    if (checkOfferTimingValid(enhancedOffer)) { return enhancedOffer.enhancedOutcomes[outcomeId]; }
    return null;
  }
  return null;
};

export const getEnhancedOutcomesById = (state) => (
  state.enhanedOutcomeOffers || { offers: [] }).offers.reduce((acc, offer) => (
  checkOfferTimingValid(offer)
    ? {
      ...acc,
      ...Object.entries(offer.enhancedOutcomes).reduce(
        (outcomeAcc, current) => {
          const currentOutcomeEntry = current[1];
          const currentOutcomeKey = current[0];

          if (currentOutcomeEntry.active) {
            return {
              ...outcomeAcc,
              [currentOutcomeKey]: currentOutcomeEntry,
            };
          }
          return outcomeAcc;
        },
        {},
      ),
    }
    : acc), {}) || {};
