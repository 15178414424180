import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { Modal, RenderInBody } from 'bv-components';
import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';

const TaxInfoDialogView = ({
  betslipType,
  potentialReturns,
  enhancedReturns,
  taxDeductPrc,
  taxDeductValue,
  netReturns,
  onToggle,
  showDialog,
  termsAndConditionsUrl,
}) => (
  <>
    { betslipType === 'mini-betslip' && (
      <div className="betslip__tax-information" onClick={onToggle}>
        <span className="bvs-icon is-info-alt" />
        <span>{t('betslip.tax_info.collapsed', { percent: taxDeductPrc })}</span>
      </div>
    )}
    { betslipType === 'full-betslip' && taxDeductValue >= 0.01 && <span className="bvs-icon is-info-alt" onClick={onToggle} />}
    { showDialog && (
      <RenderInBody>
        <Modal
          info
          title={t('betslip.tax_info.dialog_title')}
          className="betslip-tax-info-info"
          actions={[{
            label: t('betslip.bonus_eligibility_dialog.close_button'),
            onClick: onToggle,
            className: 'bvs-button is-secondary',
          }]}
        >
          <>
            {
              // check if we have actually gonna deduct at least a full penny
              taxDeductValue >= 0.01
                ? (
                  <div className="betslip__tax-dialog__summary">
                    <div className="betslip__tax-dialog__row">
                      <span className="label">{t('betslip.tax_info.gross_returns')}</span>
                      <span className="value">{toCurrency(potentialReturns, { decimalPlaces: 2 })}</span>
                    </div>
                    {enhancedReturns > 0 && (
                      <div className="betslip__tax-dialog__row">
                        <span className="label">{t('betslip.tax_info.enhanced_returns')}</span>
                        <span className="value">{toCurrency(enhancedReturns, { decimalPlaces: 2 })}</span>
                      </div>
                    )}
                    <div className="betslip__tax-dialog__row">
                      <span className="label">{t('betslip.tax_info.tax_deduct_percent', { percent: taxDeductPrc })}</span>
                      <span className="value">{toCurrency(taxDeductValue, { decimalPlaces: 2 })}</span>
                    </div>
                    <div className="betslip__tax-dialog__row">
                      <span className="label">{t('betslip.tax_info.net_returns')}</span>
                      <span className="value">{toCurrency(netReturns, { decimalPlaces: 2 })}</span>
                    </div>
                  </div>
                )
                : <p>{t('betslip.tax_info.no_stake_text', { percent: taxDeductPrc })}</p>
            }
            <p dangerouslySetInnerHTML={{ __html: sanitize(t('betslip.tax_info.dialog_bottom_text', { url: termsAndConditionsUrl })) }} />
          </>
        </Modal>
      </RenderInBody>
    )}
  </>
);

TaxInfoDialogView.propTypes = {
  betslipType: PropTypes.isRequired,
  potentialReturns: PropTypes.number.isRequired,
  enhancedReturns: PropTypes.number.isRequired,
  taxDeductPrc: PropTypes.number.isRequired,
  taxDeductValue: PropTypes.number.isRequired,
  netReturns: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
  showDialog: PropTypes.bool.isRequired,
  termsAndConditionsUrl: PropTypes.string.isRequired,
};

export default TaxInfoDialogView;
