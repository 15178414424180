import PropTypes from 'prop-types';
import { handledBetIssues } from 'Betslip/helpers';

import MultipleHeaderView from './multiple_header_view';

const MultipleHeaderContainer = ({ betIssue }) => {
  if (![...handledBetIssues, 'price_change'].includes(betIssue.type)) return null;

  return (
    <MultipleHeaderView betIssue={betIssue} />
  );
};

MultipleHeaderContainer.propTypes = {
  betIssue: PropTypes.isRequired,
};

export default MultipleHeaderContainer;
