import { t } from 'bv-i18n';

const Header = () => (
  <div className="betslip__header">
    <span className="bvs-icon is-check-alt is-big bet-receipt-success-icon" />
    <h4 className="bvs-h4">{t('betslip.receipt.success_title')}</h4>
  </div>
);

export default Header;
