import PropTypes from 'prop-types';
import { splitOutcomeDescriptionWithLine } from 'Betslip/helpers';

const OutcomeDescription = ({ description, lineChanged }) => {
  if (lineChanged) {
    const [desc, lineValue] = splitOutcomeDescriptionWithLine(description);
    return (
      <div className="betslip-single__outcome line-changed">
        {desc}
        <span className="betslip-single__description-line">
          {lineValue}
        </span>
      </div>
    );
  }

  return <div className="betslip-single__outcome">{description}</div>;
};

OutcomeDescription.propTypes = {
  description: PropTypes.string.isRequired,
  lineChanged: PropTypes.string.isRequired,
};

export default OutcomeDescription;
