import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { withErrorBoundary } from 'bv-error-boundary';
import { getBetslipSingleOutcomeIds } from 'sportsbook-betslip-selector';

import SinglesView from './singles_view';

const SinglesRedux = ({ keyboardRef }) => {
  const singleOutcomeIds = useSelector((state) => getBetslipSingleOutcomeIds(state), isEqual);
  return <SinglesView keyboardRef={keyboardRef} singleOutcomeIds={singleOutcomeIds} />;
};

SinglesRedux.propTypes = {
  keyboardRef: PropTypes.isRequired,
};

export default withErrorBoundary(SinglesRedux, { FallbackComponent: () => <div /> });
