import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

import {
  isSuspended,
  hasPriceChanged,
  isHidden,
  handledBetIssues,
  hasLineChanged,
  hasLineFollowed,
} from 'Betslip/helpers';

const SingleHeaderView = ({
  outcome, single, betIssue,
}) => {
  const errorLevel = betIssue && betIssue.type && handledBetIssues.includes(betIssue.type) ? 'is-danger' : 'is-warning';

  return (
    <div className={`betslip__status ${errorLevel}`}>
      <span className={`bvs-icon ${errorLevel}`} />
      <span>
        {
          (betIssue.type && betIssue.errorMessage)
          || (isHidden(outcome) && t('betslip.header.selection_unavailable'))
          || (isSuspended(outcome) && t('betslip.header.market_suspended'))
          || (hasLineFollowed(single) && t('betslip.header.line_change_been_followed'))
          || (hasLineChanged(single) && t('betslip.header.line_has_changed'))
          || (hasPriceChanged(single) && t('betslip.header.price_has_changed'))
        }
      </span>
    </div>
  );
};

SingleHeaderView.propTypes = {
  outcome: PropTypes.isRequired,
  single: PropTypes.isRequired,
  betIssue: PropTypes.isRequired,
};

export default SingleHeaderView;
