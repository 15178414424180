import PropTypes from 'prop-types';

const Market = ({ marketTypeDescription }) => (
  <div className="betslip-single__market">
    {marketTypeDescription}
  </div>
);

Market.propTypes = {
  marketTypeDescription: PropTypes.string.isRequired,
};

export default Market;
