import { t } from 'bv-i18n';

const BonusSignPosting = () => (
  <div className="bonus-funds-eligible">
    {t('betslip.bonus_available')}
    <span className="bvs-icon bvs-icon is-promo-cash" />
  </div>
);

export default BonusSignPosting;
