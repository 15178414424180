import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { isEqual, isEmpty } from 'underscore';
import { useSelector } from 'react-redux';
import { hasAnyPriceChanges } from 'Betslip/helpers';
import { getBetIssue, getBetslipSingles } from 'sportsbook-betslip-selector';

import MultipleHeaderContainer from './multiple_header_container';

const MultipleHeaderRedux = ({ stakeId }) => {
  let betIssue = useSelector((state) => getBetIssue(state, stakeId), isEqual);
  const singles = useSelector(getBetslipSingles, isEqual);

  if (hasAnyPriceChanges(singles) && isEmpty(betIssue)) {
    betIssue = {
      type: 'price_change',
      errorMessage: t('betslip.header.price_has_changed'),
    };
  }

  return (
    <MultipleHeaderContainer betIssue={betIssue} />
  );
};

MultipleHeaderRedux.propTypes = {
  stakeId: PropTypes.isRequired,
};

export default MultipleHeaderRedux;
