import { useContext } from 'react';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { multiplesFilter, isAnySuspended } from 'Betslip/helpers';
import BetslipContext from 'Betslip/context';
import {
  makeGetBetslipOutcomes,
  getBetslipMultiples,
  getCurrentTab,
  getMultiplesExpanded,
} from 'sportsbook-betslip-selector';

const getBetslipOutcomes = makeGetBetslipOutcomes();

export default ({ removeSuspended } = { removeSuspended: false }) => {
  let multiples = null;
  const { tabsEnabled } = useContext(BetslipContext);
  const currentTab = useSelector(getCurrentTab, isEqual);
  let multiplesExpanded = useSelector(getMultiplesExpanded, isEqual);
  const outcomes = useSelector(getBetslipOutcomes, isEqual);
  const allMultiples = useSelector(getBetslipMultiples, isEqual);
  const filteredMultiples = allMultiples
    .filter(multiplesFilter(tabsEnabled, currentTab, multiplesExpanded));

  // if the filtering returns nothing, we need to show all of the
  // multiples and pretend it has been expanded (regardless of redux state)
  // this is because forecase, reverse, tricast bet-types have weird multiplicity values
  // but only if betslip tabs are not enabled.
  if (!tabsEnabled && allMultiples.length > 0 && filteredMultiples.length === 0) {
    multiples = allMultiples;
    multiplesExpanded = true;
  } else {
    multiples = filteredMultiples;
  }

  // if any outcome is suspended, all multiples are suspended
  if (removeSuspended && isAnySuspended(outcomes)) {
    multiples = [];
  }

  return {
    multiples,
    multiplesExpanded,
    unfilteredMultiplesCount: allMultiples.length,
  };
};
