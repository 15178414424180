import PropTypes from 'prop-types';

const Legs = ({ legs }) => (
  <div className="betslip-single__outcome-legs">
    {legs.map(({ description }) => (
      <div className="betslip-single__outcome-leg">
        <span>{description}</span>
      </div>
    ))}
  </div>
);

Legs.propTypes = {
  legs: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
  })).isRequired,
};

export default Legs;
