import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import classnames from 'classnames';

import Multiple from './multiple';
import ShowMoreView from './show_more_view';

const MultiplesView = ({
  multiples,
  suspended,
  anySpSingle,
  keyboardRef,
  multiplesExpanded,
  multiplesCount,
  tabsEnabled,
  priceChanged,
}) => (
  <div className={classnames('betslip__multiples', { 'bet--suspended': suspended })}>
    <h5 className="bvs-h5">{t('betslip.multiples.header')}</h5>
    <div className="bvs-card">
      {multiples.map(
        (multiple) => (
          <Multiple
            keyboardRef={keyboardRef}
            anySpSingle={anySpSingle}
            multiple={multiple}
            priceChanged={priceChanged}
          />
        ),
      )}
      {!tabsEnabled && !multiplesExpanded && multiplesCount > 1 && <ShowMoreView />}
    </div>
  </div>
);

MultiplesView.propTypes = {
  multiples: PropTypes.isRequired,
  suspended: PropTypes.isRequired,
  keyboardRef: PropTypes.isRequired,
  anySpSingle: PropTypes.bool.isRequired,
  multiplesExpanded: PropTypes.bool.isRequired,
  multiplesCount: PropTypes.number.isRequired,
  tabsEnabled: PropTypes.bool.isRequired,
  priceChanged: PropTypes.bool.isRequired,
};

export default MultiplesView;
