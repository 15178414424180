import { useRef, useEffect, useState } from 'react';
import { RenderInNode } from 'bv-components';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getCookie, isDesktop } from 'bv';
import { t } from 'bv-i18n';
import { format as formatNumber } from 'bv-helpers/number';
import Keyboard from 'BetslipKeypad/components/keyboard';
import QuickStakes from 'Betslip/components/full_betslip/quick_stakes';

const StakeInputView = ({
  stake,
  setStake,
  isEW,
  keyboardRef,
  sportId,
  stakeId,
  betslipType,
  disabled,
}) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  const cookieVal = getCookie('currency_prefix');
  const currencySign = cookieVal ? decodeURIComponent(cookieVal) : '';
  const currencyClassName = classnames('currency', {
    'currency--large': currencySign.length > 2,
  });

  useEffect(() => {
    if (disabled) {
      if (keyboardRef.current) keyboardRef.current.prevSetFocused = null;
      setFocused(false);
    }
  }, [disabled]);

  return (
    <div className="stake-input-wrapper">
      {currencySign.length > 0 && <span className={currencyClassName}>{currencySign}</span>}
      <input
        readOnly={!isDesktop()}
        disabled={disabled}
        ref={inputRef}
        onClick={() => {
          if (keyboardRef.current.prevSetFocused) {
            keyboardRef.current.prevSetFocused(false);
          }
          keyboardRef.current.prevSetFocused = setFocused;
          if (parseFloat(stake.value.replace(',', '.')) === 0) {
            setStake('');
          }
          setFocused(true);
        }}
        onBlur={() => {
          // when on desktop, focus loss on the input field shoud "close"
          // the invisible keypad
          if (!isDesktop()) return;
          keyboardRef.current.prevSetFocused = null;
          setFocused(false);
        }}
        type="text"
        value={stake.value}
        placeholder={focused ? formatNumber('0.00') : t('betslip.stake_placeholder')}
        data-cy="stake-input"
      />
      {isEW && <span className="multiplier">X2</span>}
      { focused && !disabled && (
        <RenderInNode node={keyboardRef.current}>
          <div className={classnames('betslip-keyboard__wrapper', { hidden: isDesktop() })}>
            { betslipType === 'full-betslip' && <QuickStakes sportId={sportId} stakeId={stakeId} /> }
            <Keyboard
              onEnterClick={() => {
                keyboardRef.current.prevSetFocused = null;
                setFocused(false);
                if (inputRef.current) inputRef.current.blur();
              }}
              onChange={(value) => {
                if (inputRef.current) inputRef.current.focus();
                setStake(value);
              }}
              value={stake.value}
            />
          </div>
        </RenderInNode>
      )}
    </div>
  );
};

StakeInputView.propTypes = {
  sportId: PropTypes.isRequired,
  stake: PropTypes.isRequired,
  setStake: PropTypes.func.isRequired,
  isEW: PropTypes.bool.isRequired,
  keyboardRef: PropTypes.isRequired,
  stakeId: PropTypes.isRequired,
  betslipType: PropTypes.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default StakeInputView;
/* eslint no-param-reassign: 'off', jsx-a11y/label-has-associated-control: 'off'  */
