import { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { useEventBus } from 'bv-hooks';
import withLazyLoad from 'bv-lazy-load';
import { locale } from 'bv-i18n';

const CrackTheCodeProgress = withLazyLoad(<Spinner />)('crackTheCodeProgress');

const CrackTheCode = ({ id }) => {
  const [data, setData] = useState();

  useEventBus(
    `/ctc/bet-receipt/${id}/${locale()}`,
    (err, response) => {
      if (err || !response?.body) return;

      setData(response.body);
    },
    [id],
  );

  if (!data?.codes || !data?.sportsbookProgress) return null;

  return (
    <CrackTheCodeProgress
      type="bet-receipt"
      codes={data.codes}
      progress={data?.sportsbookProgress}
      descriptions={data?.descriptions}
    />
  );
};

CrackTheCode.propTypes = {
  id: PropTypes.number.isRequired,
};

export default CrackTheCode;
