import { compose, isEqual } from 'underscore';
import { t } from 'bv-i18n';
import withLazyLoad from 'bv-lazy-load';
import { useDispatch, useSelector } from 'react-redux';
import { getSubmitResponse } from 'sportsbook-betslip-selector';
import { betslipSubmitClear } from 'sportsbook-betslip-duck';
import { replaceFragment } from 'bv-helpers/location';
import { RenderInBody } from 'bv-components';

const StakeLimitsBreachModal = withLazyLoad()('stakeLimitsBreachModal');

const StakeLimitsBreach = () => {
  const dispatch = useDispatch();
  const submitResponse = useSelector(getSubmitResponse, isEqual);

  if (submitResponse.type !== 'stake_limits_breached') return null;

  return (
    <RenderInBody>
      <StakeLimitsBreachModal
        title={() => t('stake_limits.breach_modal.sbk.error.title')}
        subtitle={submitResponse.message}
        limits={submitResponse.stakeLimits}
        stakeLimitsPageUrl="/safer_gambling/portal/sbk_stake_limits"
        onEditBtnClick={() => {
          replaceFragment('/safer_gambling/portal/stake_limits');
          dispatch(betslipSubmitClear());
        }}
        onCloseBtnClick={compose(dispatch, betslipSubmitClear)}
        translationsNamespace="stake_limits"
      />
    </RenderInBody>
  );
};

export default StakeLimitsBreach;
