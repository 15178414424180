import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { makeGetSingle, getBetIssue } from 'sportsbook-betslip-selector';
import { getStakeId } from 'Betslip/helpers';
import withOutcome from 'sportsbook-with-outcome';

import SingleHeaderContainer from './single_header_container';

const getSingle = makeGetSingle();

const SingleHeaderRedux = ({ outcomeId, outcome }) => {
  const single = useSelector((state) => getSingle(state, { outcomeId }), isEqual);
  const stakeId = getStakeId(single);
  const betIssue = useSelector((state) => getBetIssue(state, stakeId), isEqual);

  return (
    <SingleHeaderContainer
      single={single}
      outcome={outcome}
      betIssue={betIssue}
    />
  );
};

SingleHeaderRedux.propTypes = {
  outcome: PropTypes.isRequired,
  outcomeId: PropTypes.isRequired,
};

export default withOutcome(SingleHeaderRedux, { idAttribute: 'outcomeId' });
