import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { set as lsSet } from 'bv-local-storage';
import { setStakeEWS } from 'sportsbook-betslip-duck';
import { getStake } from 'sportsbook-betslip-selector';

import EwToggleView from './ew_toggle_view';

const EwToggleRedux = ({ stakeId }) => {
  const dispatch = useDispatch();
  const stake = useSelector((state) => getStake(state, stakeId), isEqual);
  const toggleEWS = () => {
    lsSet(`stakes-v2-${stakeId}`, { value: stake.value, ews: !stake.ews });
    dispatch(setStakeEWS(stakeId, !stake.ews));
  };

  return <EwToggleView stakeId={stakeId} toggleEWS={toggleEWS} active={stake.ews} />;
};

EwToggleRedux.propTypes = {
  stakeId: PropTypes.isRequired,
};

export default EwToggleRedux;
