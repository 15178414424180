import { useState } from 'react';
import PropTypes from 'prop-types';
import MultipleView from './multiple_view';

const MultipleContainer = ({
  multiple, keyboardRef, anySpSingle, priceChanged,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const onInfoClose = () => { setShowInfo(false); };
  const onInfoClick = () => { setShowInfo(true); };

  return (
    <MultipleView
      showInfo={showInfo}
      onInfoClose={onInfoClose}
      onInfoClick={onInfoClick}
      keyboardRef={keyboardRef}
      multiple={multiple}
      anySpSingle={anySpSingle}
      priceChanged={priceChanged}
    />
  );
};

MultipleContainer.propTypes = {
  multiple: PropTypes.isRequired,
  keyboardRef: PropTypes.isRequired,
  anySpSingle: PropTypes.bool.isRequired,
  priceChanged: PropTypes.bool.isRequired,
};

export default MultipleContainer;
