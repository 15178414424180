import PropTypes from 'prop-types';

import {
  isSuspended,
  hasPriceChanged,
  hasLineChanged,
  hasLineFollowed,
  isHidden,
  handledBetIssues,
} from 'Betslip/helpers';

import SingleHeaderView from './single_header_view';

const SingleHeaderContainer = ({
  outcome, single, betIssue,
}) => {
  const shouldShow = (
    isSuspended(outcome)
    || isHidden(outcome)
    || hasPriceChanged(single)
    || handledBetIssues.includes(betIssue.type)
    || hasLineFollowed(single)
    || hasLineChanged(single)
  );

  if (!shouldShow) return null;

  return (
    <SingleHeaderView
      outcome={outcome}
      single={single}
      betIssue={betIssue}
    />
  );
};

SingleHeaderContainer.propTypes = {
  outcome: PropTypes.isRequired,
  single: PropTypes.isRequired,
  betIssue: PropTypes.isRequired,
};

export default SingleHeaderContainer;
