import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import { getSubmitResponse } from 'sportsbook-betslip-selector';
import GeneralErrorView from './general_error_view';

const KNOWN_ERROR_TYPES = [
  'stake_limits_breached',
  'review',
  'insufficient_funds',
  'loss_limits_breached',
];

const GeneralError = () => {
  const submitResponse = useSelector(getSubmitResponse, isEqual);

  // skip if not an error response
  if (submitResponse.success !== false) return null;
  // skip if the error type is handled elsewhere
  if (KNOWN_ERROR_TYPES.includes(submitResponse.type)) return null;

  return <GeneralErrorView message={submitResponse.message} />;
};

export default GeneralError;
