import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import withOutcome from 'sportsbook-with-outcome';
import { makeGetSingle, getCurrentTab } from 'sportsbook-betslip-selector';

import SingleContainer from './single_container';

const getSingle = makeGetSingle();

const SingleRedux = ({
  outcome, removeFromBetslip, outcomeId, keyboardRef,
}) => {
  const single = useSelector((state) => getSingle(state, { outcomeId }), isEqual);
  const currentTab = useSelector(getCurrentTab, isEqual);

  return (
    <SingleContainer
      keyboardRef={keyboardRef}
      outcomeId={outcomeId}
      outcome={outcome}
      single={single}
      removeFromBetslip={removeFromBetslip}
      currentTab={currentTab}
    />
  );
};

SingleRedux.propTypes = {
  removeFromBetslip: PropTypes.isRequired,
  outcome: PropTypes.isRequired,
  outcomeId: PropTypes.number.isRequired,
  keyboardRef: PropTypes.isRequired,
};

export default withOutcome(SingleRedux, { idAttribute: 'outcomeId' });
