import PropTypes from 'prop-types';
import { t, exist } from 'bv-i18n';
import EwToggle from 'Betslip/components/ew_toggle';

const MultipleDescription = ({
  stakeId,
  description,
  ew,
  onInfoClick,
  winBetTypeId,
  multiplicity,
}) => (
  <div className="betslip-single__description">
    <div className="betslip__multiples__header">
      {exist(`betslip_bet_type_tooltips.${winBetTypeId}`) && (
        <button className="betslip-stake-info" type="button" onClick={onInfoClick}>
          <span className="bvs-icon is-info-alt" />
        </button>
      )}
      <span className="betslip__outcome-description">
        <span className="betslip-single__outcome">
          {description}
          {' '}
          {t('betslip.multiples.multiplicity_label', { multiplicity })}
        </span>
        {
          ew && (
            <div className="place-terms-description">
              <EwToggle stakeId={stakeId} />
            </div>
          )
        }
      </span>
    </div>
  </div>
);

MultipleDescription.propTypes = {
  stakeId: PropTypes.isRequired,
  description: PropTypes.isRequired,
  ew: PropTypes.isRequired,
  onInfoClick: PropTypes.isRequired,
  winBetTypeId: PropTypes.isRequired,
  multiplicity: PropTypes.isRequired,
};

export default MultipleDescription;
