import PropTypes from 'prop-types';
import { potentialReturns } from 'Betslip/helpers';

const ReturnsRow = ({ label, returns }) => (
  <div className="description_row">
    <span>{label}</span>
    <span>{potentialReturns(returns)}</span>
  </div>
);

ReturnsRow.propTypes = {
  label: PropTypes.string.isRequired,
  returns: PropTypes.isRequired,
};

export default ReturnsRow;
