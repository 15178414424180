import { safeAdd, safeMul } from 'bv-helpers/number';
import { getStakeId } from '../helpers';

export default (singles, multiples, stakes) => (
  [...singles, ...multiples].reduce((acc, bet) => {
    const stake = stakes[getStakeId(bet)];
    const multiplicity = bet.multiplicity || 1;

    if (!stake) return acc;

    const value = parseFloat(stake.value.replace(',', '.'));
    if (Number.isNaN(value)) return acc;
    return safeAdd(acc, safeMul(value, multiplicity * (stake.ews ? 2 : 1)));
  }, 0)
);
