import PropTypes from 'prop-types';

const GeneralErrorView = ({ message }) => (
  <div className="betslip__general-error betslip__status is-danger">
    <span className="bvs-icon is-danger" />
    <span>{message}</span>
  </div>
);

GeneralErrorView.propTypes = {
  message: PropTypes.string.isRequired,
};

export default GeneralErrorView;
