import PropTypes from 'prop-types';
import { OnOff } from 'bv-components';
import { t } from 'bv-i18n';
import classnames from 'classnames';
import PromocasInfoDialog from './promocash_info_dialog';

const PromocashToggleView = ({ usePromocash, onToggle, disabled }) => (
  <div className="betslip__details__toggle-wrapper">
    <span>
      {t('betslip.promocash_toggle.label')}
      <span className="bvs-icon is-promo-cash" />
    </span>
    <div className="toggle">
      { disabled && <PromocasInfoDialog /> }
      <OnOff
        onClick={onToggle}
        checked={usePromocash}
        className={classnames({ disabled })}
        disabled={disabled}
      />
    </div>
  </div>

);

PromocashToggleView.propTypes = {
  usePromocash: PropTypes.isRequired,
  onToggle: PropTypes.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PromocashToggleView;
/* eslint jsx-a11y/label-has-associated-control: 'off'  */
