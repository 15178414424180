import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { OnOff, SmallSpinner } from 'bv-components';

const ToggleView = ({
  checked, loading, onClick,
}) => (
  <div className="betslip__details__toggle-wrapper">
    <span>{t('accept_all_price_changes_toggle.label')}</span>
    <div className="toggle">
      {loading && <SmallSpinner />}
      <OnOff
        className="small"
        onClick={onClick}
        checked={checked}
      />
    </div>
  </div>
);

ToggleView.propTypes = {
  checked: PropTypes.isRequired,
  onClick: PropTypes.isRequired,
  loading: PropTypes.isRequired,
};

export default ToggleView;
