import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { getStake } from 'sportsbook-betslip-selector';
import { setStakeValue } from 'sportsbook-betslip-duck';
import QuickStakesContainer from 'BetslipKeypad/components/quick_stakes_container';
import getQuickStakes from 'BetslipKeypad/api/quick_stakes';
import { isDesktop } from 'bv';

// Prefetch quick stakes
if (!isDesktop()) {
  getQuickStakes();
}

const QuickStakes = ({ sportId, stakeId }) => {
  const dispatch = useDispatch();
  const stakeValue = useSelector((state) => getStake(state, stakeId), isEqual);

  if (isDesktop()) {
    return null;
  }

  const onChange = (value) => {
    dispatch(setStakeValue(stakeId, value));
  };

  return (
    <QuickStakesContainer
      onChange={onChange}
      sportId={sportId}
      value={stakeValue.value}
    />
  );
};

QuickStakes.propTypes = {
  sportId: PropTypes.isRequired,
  stakeId: PropTypes.isRequired,
};

export default QuickStakes;
