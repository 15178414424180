import PropTypes from 'prop-types';
import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';
import { potentialReturns as formatPotentialReturns } from 'Betslip/helpers';
import TaxInfo from 'Betslip/components/tax_info_dialog';

const PotentialReturnsView = ({
  totalStake, potentialReturns, normalReturns, enhancedReturns,
}) => (
  <div className="betslip__details__summary">
    <div className="summary_row" data-cy="total-stake">
      <span className="summary_label">
        {t('betslip.summary.total_stake')}
        :
      </span>
      <span className="summary_value">
        {toCurrency(totalStake, { decimalPlaces: 2 })}
      </span>
    </div>
    {enhancedReturns > 0 && (
      <>
        <div className="summary_row" data-cy="total-normal-returns">
          <span className="summary_label">
            {t('betslip.summary.total_normal_returns')}
            :
          </span>
          <span className="summary_value">{formatPotentialReturns(normalReturns)}</span>
        </div>
        <div className="summary_row" data-cy="total-enhanced-returns">
          <span className="summary_label">
            {t('betslip.summary.total_enhanced_returns')}
            :
          </span>
          <span className="summary_value">{formatPotentialReturns(enhancedReturns)}</span>
        </div>
      </>
    )}
    <div className="summary_row" data-cy="total-potential-returns">
      <span className="summary_label">
        {t('betslip.summary.total_potential_returns')}
        :
      </span>
      <span className="summary_value">
        <TaxInfo />
        {formatPotentialReturns(potentialReturns)}
      </span>
    </div>
  </div>
);

PotentialReturnsView.propTypes = {
  potentialReturns: PropTypes.number.isRequired,
  totalStake: PropTypes.number.isRequired,
  normalReturns: PropTypes.number.isRequired,
  enhancedReturns: PropTypes.number.isRequired,
};

export default PotentialReturnsView;
