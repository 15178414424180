import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { updateLineDescription } from 'Betslip/helpers';

const MarketDescription = ({ description, lineChanged, requestedLineExtraInfo }) => {
  if (lineChanged) {
    return (
      <div className="betslip-single__market">
        {updateLineDescription(description, description, requestedLineExtraInfo)}
        {' '}
        (
        {t('betslip.receipt.requested')}
        )
      </div>
    );
  }

  return <div className="betslip-single__market">{description}</div>;
};

MarketDescription.propTypes = {
  description: PropTypes.string.isRequired,
  lineChanged: PropTypes.string.isRequired,
  requestedLineExtraInfo: PropTypes.string.isRequired,
};

export default MarketDescription;
