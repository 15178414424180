import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';

const StakeRow = ({ promocash, totalStake }) => (
  <div className="description_row">
    <span>{t('betslip.receipt.stake')}</span>
    <span>
      {promocash && <span className="bvs-icon is-promo-cash" />}
      {toCurrency(totalStake, { decimalPlaces: 2 })}
    </span>
  </div>
);

StakeRow.propTypes = {
  promocash: PropTypes.bool.isRequired,
  totalStake: PropTypes.number.isRequired,
};

export default StakeRow;
