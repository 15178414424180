import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { format as priceFormat } from 'bv-helpers/price';
import PriceFormatContext from 'Index/contexts/price_format_context';

const spFallback = (price, priceFormatId) => (price ? priceFormat(price, priceFormatId) : t('javascript.na'));

const PriceRow = ({ selection, fractionalEnhancedOdds }) => (
  <PriceFormatContext.Consumer>
    {(priceFormatId) => (
      <div className="description_row">
        <span>{t('betslip.receipt.odds')}</span>
        <span>
          {
          fractionalEnhancedOdds ? (
            <>
              <span className="enhanced-price">
                <span className="bvs-event-icon is-bet-boost" />
                {priceFormat(fractionalEnhancedOdds)}
              </span>
              <span className={classnames('old-price', { 'price-changed': selection.priceChanged })}>
                {spFallback(selection.formattedPrice, priceFormatId)}
              </span>
            </>
          ) : (
            <span className={classnames({ 'odds-changed': selection.priceChanged })}>
              {spFallback(selection.formattedPrice, priceFormatId)}
            </span>
          )
        }
          { selection.priceChanged && (
          <span className="requested-price">
            {' '}
            (
            {
              selection.formattedRequestedPrice
                ? t('betslip.receipt.requested_price', {
                  requested: priceFormat(selection.formattedRequestedPrice, priceFormatId),
                })
                : t('javascript.na')
            }
            )
          </span>
          )}
        </span>
      </div>
    )}
  </PriceFormatContext.Consumer>
);

PriceRow.propTypes = {
  selection: PropTypes.isRequired,
  fractionalEnhancedOdds: PropTypes.isRequired,
};

export default PriceRow;
