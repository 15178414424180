import { subscribe } from 'bv-services/internal-event-bus';
import { selectAcceptPriceChanges } from './selectors';
import { INITIAL_FETCH_SUCCESS, betPlacedUpdate } from './duck';

const { store } = window.reduxState;

const onSubmitSuccess = () => {
  const state = selectAcceptPriceChanges(store.getState());
  if (state.value === 100) {
    store.dispatch(betPlacedUpdate());
  }
};

const AcceptAllPriceChangesToggleMiddleware = () => (next) => (action) => {
  const state = selectAcceptPriceChanges(store.getState());

  switch (action.type) {
    case INITIAL_FETCH_SUCCESS:
      // if this is the first time we initiating this comp (it's embedded multiple times)
      if (!state.initiated) {
        subscribe('Betslip::SubmitSuccess', onSubmitSuccess);
      }
      break;
    default:
      break;
  }

  next(action);
};

export default AcceptAllPriceChangesToggleMiddleware;
