import { compose, isEqual } from 'underscore';
import { t } from 'bv-i18n';
import withLazyLoad from 'bv-lazy-load';
import { useDispatch, useSelector } from 'react-redux';
import { getSubmitResponse } from 'sportsbook-betslip-selector';
import { betslipSubmitClear } from 'sportsbook-betslip-duck';
import { replaceFragment } from 'bv-helpers/location';
import { RenderInBody } from 'bv-components';

const LossLimitsBreachModal = withLazyLoad()('lossLimitsBreachModal');

const LossLimitsBreach = () => {
  const dispatch = useDispatch();
  const submitResponse = useSelector(getSubmitResponse, isEqual);

  if (submitResponse.type !== 'loss_limits_breached') return null;

  return (
    <RenderInBody>
      <LossLimitsBreachModal
        title={() => t('loss_limits.breach_modal.sbk.error.title')}
        subtitle={submitResponse.message}
        limits={submitResponse.lossLimits}
        onEditBtnClick={() => {
          replaceFragment('/safer_gambling/portal/loss_limits');
          dispatch(betslipSubmitClear());
        }}
        onCloseBtnClick={compose(dispatch, betslipSubmitClear)}
        translationsNamespace="loss_limits"
      />
    </RenderInBody>
  );
};

export default LossLimitsBreach;
