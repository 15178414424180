import { useSelector } from 'react-redux';
import { isEqual } from 'underscore';
import { getEnhancedOutcomesById } from 'EnhanedOutcomeOffers/selectors';
import totalStake from 'Betslip/services/total_stake';
import { mergeEnhancedOutcomesWithSingles } from 'Betslip/helpers';
import {
  getStakes,
  getUsePromocash,
  getRemoveStakeForBonusBet,
  getTaxDeductPrc,
} from 'sportsbook-betslip-selector';

import {
  netTotalPotentialReturns,
  totalNormalReturns,
  totalEnhancedReturns,
} from 'Betslip/services/potential_returns';
import filteredSingles from 'Betslip/services/filtered_singles';
import filteredMultiples from 'Betslip/services/filtered_multiples';

import SummaryView from './summary_view';

const SummaryRedux = () => {
  const taxDeductPrc = useSelector(getTaxDeductPrc, isEqual);
  const stakes = useSelector(getStakes, isEqual);
  const removeStakeForBonusBet = useSelector(getRemoveStakeForBonusBet, isEqual);
  const usePromocash = useSelector(getUsePromocash, isEqual);
  const enhancedOutcomesById = useSelector(getEnhancedOutcomesById, isEqual);

  const { singles, allSingles } = filteredSingles({ removeSuspended: true });
  const { multiples } = filteredMultiples({ removeSuspended: true });

  const potentialReturns = netTotalPotentialReturns({
    singles,
    singlesForMultiplesCalculations: allSingles,
    multiples,
    enhancedOutcomesById: mergeEnhancedOutcomesWithSingles(enhancedOutcomesById, singles),
    removeStakeForBonusBet,
    usePromocash,
    stakes,
  }, taxDeductPrc);

  const normalReturns = totalNormalReturns({
    singles,
    singlesForMultiplesCalculations: allSingles,
    multiples,
    stakes,
    usePromocash,
    removeStakeForBonusBet,
  });

  const enhancedReturns = totalEnhancedReturns({
    singles,
    singlesForMultiplesCalculations: allSingles,
    enhancedOutcomesById: mergeEnhancedOutcomesWithSingles(enhancedOutcomesById, singles),
    stakes,
    usePromocash,
    removeStakeForBonusBet,
  });

  return (
    <SummaryView
      totalStake={totalStake(singles, multiples, stakes)}
      potentialReturns={potentialReturns}
      enhancedReturns={enhancedReturns}
      normalReturns={normalReturns}
    />
  );
};

export default SummaryRedux;
