import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLoggedIn } from 'bv-hooks';

import ToggleContainer from './toggle_container';
import { onToggle } from '../thunks';
import { initialFetchSuccess } from '../duck';
import { selectAcceptPriceChanges } from '../selectors';
import { TOGGLE_ENABLED } from '../constants';

const ToggleRedux = ({
  showIfEnabled,
  acceptablePriceChangePct,
}) => {
  const dispatch = useDispatch();
  const loggedIn = useLoggedIn();

  const {
    initiated,
    value,
    initialValue,
    seenBetPlacement,
    loading,
  } = useSelector(selectAcceptPriceChanges);

  useEffect(() => {
    if (![null, undefined].includes(acceptablePriceChangePct)) {
      dispatch(initialFetchSuccess(acceptablePriceChangePct));
    }
  }, [acceptablePriceChangePct]);

  const handleToggle = (newValue) => {
    dispatch(onToggle(newValue));
  };

  // component is hidden:
  // - if not logged in
  // - if the showIfEnabled is false
  // - if initial fetch is running
  // - if initial value is 100% already
  // - if there was a bet placement with the value set to a 100%
  if (!showIfEnabled && (
    !initiated
    || (initiated && initialValue === TOGGLE_ENABLED)
    || seenBetPlacement
    || !loggedIn
  )) {
    return null;
  }

  return (
    <ToggleContainer
      value={value}
      loading={loading}
      onToggle={handleToggle}
    />
  );
};

ToggleRedux.propTypes = {
  showIfEnabled: PropTypes.bool,
  acceptablePriceChangePct: PropTypes.number.isRequired,
};

ToggleRedux.defaultProps = {
  showIfEnabled: false,
};

export default ToggleRedux;
