import { useRef } from 'react';
import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { useLockBodyScroll } from 'bv-hooks';
import BetreceiptButtons from 'Betslip/components/betreceipt/buttons';
import BetreceiptSummary from 'Betslip/components/betreceipt/betreceipt_summary';
import BetreceiptHeader from 'Betslip/components/betreceipt/header';
import SingleReceipt from 'Betslip/components/betreceipt/single_receipt';
import MultipleReceipt from 'Betslip/components/betreceipt/multiple_receipt';
import CrackTheCode from 'Betslip/components/betreceipt/crack_the_code';

const BetreceiptView = ({
  id,
  outcomesById,
  singles,
  multiples,
  onClearAndClose,
  onReuse,
  canReuse,
  totalStake,
  grossTotalPotentialReturns,
  promocash,
}) => {
  const rightSidebarScrollableRef = useRef(null);

  useLockBodyScroll(rightSidebarScrollableRef);

  return (
    <div className="betslip-receipt-wrapper">
      {id && bvVar('crackTheCode').standard && <CrackTheCode id={id} />}
      <BetreceiptHeader />
      <div className="betslip__bet-list right-sidebar-scrollable" ref={rightSidebarScrollableRef}>
        {singles.map((single) => (
          <SingleReceipt
            single={single}
            outcome={outcomesById[single.outcomeId]}
            fullDetails
          />
        ))}
        {multiples.map((multiple) => (
          <MultipleReceipt multiple={multiple} outcomes={outcomesById} />
        ))}
      </div>
      <div className="betslip__details">
        <BetreceiptSummary
          promocash={promocash}
          totalStake={totalStake}
          grossTotalPotentialReturns={grossTotalPotentialReturns}
        />
      </div>
      <BetreceiptButtons canReuse={canReuse} onReuse={onReuse} onClearAndClose={onClearAndClose} />
    </div>
  );
};

BetreceiptView.propTypes = {
  id: PropTypes.number.isRequired,
  onClearAndClose: PropTypes.func.isRequired,
  onReuse: PropTypes.func.isRequired,
  canReuse: PropTypes.bool.isRequired,
  singles: PropTypes.isRequired,
  multiples: PropTypes.isRequired,
  promocash: PropTypes.isRequired,
  totalStake: PropTypes.isRequired,
  outcomesById: PropTypes.isRequired,
  grossTotalPotentialReturns: PropTypes.isRequired,
};

export default BetreceiptView;
