import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const Buttons = ({ canReuse, onReuse, onClearAndClose }) => (
  <div className="betslip__buttons">
    {canReuse && (
      <button className="betslip__button betslip__button-reuse" onClick={onReuse} type="button">
        {t('betslip.receipt.reuse_selection_button')}
      </button>
    )}
    <button className="betslip__button betslip__button-clear" onClick={onClearAndClose} type="button">
      {t('betslip.receipt.clear_betslip_button')}
    </button>
  </div>
);

Buttons.propTypes = {
  canReuse: PropTypes.bool,
  onReuse: PropTypes.isRequired,
  onClearAndClose: PropTypes.isRequired,
};

Buttons.defaultProps = {
  canReuse: true,
};

export default Buttons;
